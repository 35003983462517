import { uuidToBase32, zColor } from ':utils/common';
import { zDateTime } from ':utils/dateTime';
import { zId, type Id } from ':utils/id';
import { z } from 'zod';
import { SubscriptionStatus, SubscriptionTierCode } from './subscription';

export type TeamOutput = z.infer<typeof zTeamOutput>;
export const zTeamOutput = z.object({
    id: zId,
    title: z.string(),
    legalName: z.string(),
    email: z.string(),
    dateTrialStarted: zDateTime.optional(),
    /** The currency used when buying subscriptions. */
    stripeCurrencyId: z.string(),
    isStripeConnected: z.boolean(),
    isPaypalConnected: z.boolean(),
});

export type TeamEdit = z.infer<typeof zTeamEdit>;
export const zTeamEdit = z.object({
    title: z.string(),
    legalName: z.string(),
    email: z.string(),
}).partial();

export enum TeamMemberRole {
    scheduler = 'scheduler',
    master = 'master',
    freelancer = 'freelancer',
}

export type TeamMemberOutput = z.infer<typeof zTeamMemberOutput>;
export const zTeamMemberOutput = z.object({
    id: zId,
    role: z.nativeEnum(TeamMemberRole),
    appUser: z.object({
        id: zId,
        firstName: z.string(),
        lastName: z.string().optional(),
        email: z.string(),
        phoneNumber: z.string().optional(),
    }),
    color: zColor,
});

export function generatePaymentEmail(team: { id: Id }): string {
    const shortUuid = uuidToBase32(team.id);
    return `p+${shortUuid}@flowlance.com`;
}

export type TeamMemberEdit = z.infer<typeof zTeamMemberEdit>;
export const zTeamMemberEdit = z.object({
    color: zColor,
});


const zTeamReferral = z.object({
    teamId: zId,
    createdAt: zDateTime,
    subscription: z.object({
        status: z.nativeEnum(SubscriptionStatus),
        tierCode: z.nativeEnum(SubscriptionTierCode),
    }),
    revenue: z.number(),
});

export const zTeamReferrals = z.object({
    totalReferrals: z.number(),
    totalEarnings: z.number(),
    referrals: z.array(zTeamReferral),
});
