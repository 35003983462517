import { z } from 'zod';
import { secondsToMilliseconds } from './dateTime';
import { zCanonizedEmail, zPassword } from './forms';
import { TeamMemberRole } from './entity/team';

export const zCredentials = z.object({
    email: zCanonizedEmail,
    password: zPassword,
});

export type AccessTokenInit = {
    /** In seconds. */
    exp: number;
    /** In seconds. */
    iat: number;
    email: string;
    role: TeamMemberRole;
};

export type AccessTokenPayload = z.infer<typeof zAccessTokenPayload>;
export const zAccessTokenPayload = z.object({
    /** In milliseconds. */
    exp: z.number().transform(value => secondsToMilliseconds(value)),
    /** In milliseconds. */
    iat: z.number().transform(value => secondsToMilliseconds(value)),
    email: z.string(),
    role: z.nativeEnum(TeamMemberRole),
});

export type AccessTokens = z.infer<typeof zAccessTokens>;
export const zAccessTokens = z.object({
    backend: z.string(),
    google: z.string().optional(),
});

export const ACCESS_TOKENS_COOKIE_NAME = 'access_tokens';

export type RegisterValidateInput = z.infer<typeof zRegisterValidateInput>;
export const zRegisterValidateInput = z.object({
    email: zCanonizedEmail,
});

export type RegisterValidateError = {
    type: 'register.email-already-exists';
    property: 'email';
};

export const zPasswordEdit = z.object({
    email: zCanonizedEmail,
    password: zPassword,
    newPassword: zPassword,
});

export const zPasswordReset = z.object({
    email: zCanonizedEmail,
});
