import { useEffect, useId, useMemo, useState } from 'react';
import { Button, Form, Modal, ScrollArea } from ':components/shadcn';
import { useTranslation } from 'react-i18next';
import { api } from ':frontend/utils/api';
import { useUser } from ':frontend/context/UserProvider';
import { Link } from 'react-router-dom';
import { routesFE } from ':utils/routes';
import { ClientContact, getClientShortcut } from ':frontend/types/Client';
import { useClients } from ':frontend/hooks';
import { ClientIconCustom } from ':frontend/components/client/ClientIconLink';
import clsx from 'clsx';
import { type Signal, useSignal } from '@preact/signals-react';
import { SpinnerButton } from ':frontend/components/common';
import { clientContactToServer } from ':frontend/types/EventParticipant';
import useNotifications from ':frontend/context/NotificationProvider';
import { createErrorAlert, createTranslatedSuccessAlert } from ':frontend/components/notifications';
import { Query } from ':frontend/utils/common';
import { trpc } from ':frontend/context/TrpcProvider';
import type { EmptyObject } from ':utils/common';

type ImportClientsModalProps = Readonly<{
    onClose(): void;
}>;

export function ImportClientsModal({ onClose }: ImportClientsModalProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'importClients.modal' });
    const { appUser } = useUser();

    return (
        <Modal.Root open onOpenChange={() => onClose()}>
            <Modal.Content className='gap-6' closeButton={t('close-button')}>
                <Modal.Header className='space-y-4'>
                    <img className='h-8 w-auto' src={'/static/integration-icons/google-calendar.svg'} />

                    <Modal.Title className='text-3xl leading-9 text-center font-semibold text-secondary-900'>
                        {t(appUser.google.isContacts ? 'title-enabled' : 'title-not-enabled')}
                    </Modal.Title>
                </Modal.Header>

                {appUser.google.isContacts
                    ? <ClientsEnabled onClose={onClose} />
                    : <ClientsNotEnabled />}
            </Modal.Content>
        </Modal.Root>
    );
}

function ClientsNotEnabled() {
    const { t } = useTranslation('pages', { keyPrefix: 'importClients' });

    return (
        <div className='space-y-4'>
            <div className='text-center text-lg leading-5'>
                {t('not-enabled-text')}
            </div>

            <div>
                <Link to={routesFE.integrations.path}>
                    <Button variant='primary' size='small' className='w-full'>{t('connect')}</Button>
                </Link>
            </div>
        </div>
    );
}

function ClientsEnabled({ onClose }: ImportClientsModalProps) {
    const { clients } = useClients();
    const [ allContacts, setAllContacts ] = useState<ClientContact[]>();
    const checked = useSignal<CheckedContacts>(getDefaultChecked());
    const reset = useSignal<EmptyObject>(getDefaultChecked().contacts);

    async function fetchContacts(signal: AbortSignal) {
        const response = await api.google.getContacts(signal);
        if (!response.status || !response.data.otherContacts)
            return;

        const fetchedContacts = response.data.otherContacts.map(ClientContact.fromServer).filter((contact): contact is ClientContact => !!contact);
        setAllContacts(fetchedContacts);
    }

    useEffect(() => {
        const [ signal, abort ] = api.prepareAbort();
        fetchContacts(signal);

        return abort;
    }, []);

    // We need only those contacts that are not already clients.
    const newContacts = useMemo(() => {
        if (!allContacts || !clients)
            return;

        const existingEmails = new Set(clients.map(client => client.email));
        const output: ClientContact[] = [];
        for (const contact of allContacts) {
            if (!existingEmails.has(contact.canonicalEmail)) {
                existingEmails.add(contact.canonicalEmail);
                output.push(contact);
            }
        }

        return output;
    }, [ allContacts, clients ]);

    const { settings } = useUser();
    const { addAlert } = useNotifications();
    const createClientsMutation = trpc.$client.createClients.useMutation();
    const trpcUtils = trpc.useUtils();

    function importClients() {
        const currentChecked = checked.peek();
        const contactsToServer = Object.entries(currentChecked.contacts)
            .filter(([ , checked ]) => checked)
            .map(([ email ]) => allContacts!.find(contact => contact.canonicalEmail === email)!)
            .map(contact => clientContactToServer(contact, settings));

        createClientsMutation.mutate(contactsToServer, {
            onError: error => {
                addAlert(createErrorAlert(error.data));
            },
            onSuccess: async response => {
                resetCheckedSignal(checked, reset);
                await trpcUtils.$client.getClientInfos.invalidate();
                addAlert(createTranslatedSuccessAlert('pages:importClients.import-success', { count: response.length }));
                onClose();
            },
        });
    }

    return (
        <div className='h-full space-y-4'>
            {newContacts && (
                <ImportClientsForm contacts={newContacts} checked={checked} reset={reset} />
            )}

            <ImportOverview checked={checked} reset={reset} onImport={importClients} isFetching={createClientsMutation.isPending} />
        </div>
    );
}

export function foo(b: string, a: number) {
    console.log(a);
}

type ImportOverviewProps = Readonly<{
    checked: Signal<CheckedContacts>;
    reset: Signal<EmptyObject>;
    onImport: () => void;
    isFetching?: boolean;
}>;

function ImportOverview({ checked, reset, onImport, isFetching }: ImportOverviewProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'importClients' });
    const count = checked.value.count;
    const isImporting = count !== 0;

    function resetChecked() {
        resetCheckedSignal(checked, reset);
    }

    if (!isImporting)
        return null;

    return (
        <div className='space-y-4'>
            <div className='text-sm text-center'>
                {t('selected-contacts-label', { count })}
            </div>

            <div className='grid grid-cols-2 gap-4'>
                <Button onClick={resetChecked} size='small' variant='secondary' disabled={isFetching}>
                    {t('cancel-button')}
                </Button>

                <SpinnerButton
                    onClick={onImport}
                    isFetching={isFetching}
                    disabled={count === 0}
                    size='small'
                    variant='primary'
                >
                    {t('import-contacts-button', { count })}
                </SpinnerButton>
            </div>
        </div>
    );
}

type ImportClientsFormProps = Readonly<{
    contacts: ClientContact[];
    checked: Signal<CheckedContacts>;
    reset: Signal<EmptyObject>;
}>;

function ImportClientsForm({ contacts, checked, reset }: ImportClientsFormProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'importClients' });
    const [ filter, setFilter ] = useState('');
    const filteredContacts = useMemo(() => {
        if (!filter)
            return contacts;

        const filterQuery = new Query(...filter.split(' '));
        return contacts.filter(contact => contact.query.match(filterQuery));
    }, [ contacts, filter ]);

    return (
        <div className='space-y-4'>
            <div className='text-center'>
                {t('contacts-count-label', { count: contacts.length })}
            </div>

            <div className='space-y-2'>
                <Form.Input
                    onChange={e => setFilter(e.target.value)}
                    value={filter}
                    placeholder={t('search-placeholder')}
                    className='w-full'
                    size='compact'
                />

                <div className='w-full rounded-md border border-secondary-100 overflow-hidden'>
                    <ScrollArea className='w-full max-h-[50vh] flex flex-col [&>div>div]:!block'>
                        <div className='w-full p-4 space-y-2'>
                            {filteredContacts.map(contact => (
                                <ContactRow key={contact.canonicalEmail} contact={contact} checked={checked} reset={reset} />
                            ))}

                            {filteredContacts.length === 0 && <div className='p-6 text-danger-500 text-center'>{t('no-contacts')}</div>}
                        </div>
                    </ScrollArea>
                </div>
            </div>
        </div>
    );
}

type ContactRowProps = Readonly<{
    contact: ClientContact;
    checked: Signal<CheckedContacts>;
    reset: Signal<EmptyObject>;
}>;

function ContactRow({ contact, checked, reset }: ContactRowProps) {
    const { email, canonicalEmail } = contact;
    const name = contact.name === email ? '' : contact.name;
    const shortcut = getClientShortcut(contact.name, email);
    const [ isImported, setIsImported ] = useState(checked.peek().contacts[canonicalEmail] ?? false);

    function inputIsImported(value: boolean) {
        setIsImported(value);
        setCheckedSignal(checked, canonicalEmail, value);
    }

    // TODO search is still rather slow. Maybe we should keep all the rows and just hide them with another signal (containing the query)?
    useEffect(() => {
        return reset.subscribe(() => setIsImported(checked.peek().contacts[canonicalEmail] ?? false));
    }, [ checked, reset, canonicalEmail ]);

    const id = useId();

    return (
        <div className='flex items-center gap-4'>
            <Form.Checkbox
                id={id}
                checked={isImported}
                onClick={e => {
                    inputIsImported(!isImported);
                    e.stopPropagation();
                }}
                className='rounded-xs'
            />

            <Form.Label htmlFor={id} className='grow mb-0 flex items-center gap-4 overflow-hidden'>
                <ClientIconCustom text={shortcut} size='lg' />

                <div className={clsx('grow self-stretch px-4 flex items-center rounded-md overflow-hidden', isImported && 'bg-primary-50')}>
                    {name ? (
                        <div>
                            <div className='truncate'>{name}</div>
                            <div className='truncate text-secondary-600'>{email}</div>
                        </div>
                    ) : (
                        <div className='truncate'>{email}</div>
                    )}
                </div>
            </Form.Label>
        </div>
    );
}

type CheckedContacts = {
    contacts: Record<string, boolean | undefined>;
    count: number;
};

// We have to use function here because the default contacts value will be mutated.
const getDefaultChecked = () => ({ contacts: {}, count: 0 });

function setCheckedSignal(checked: Signal<CheckedContacts>, contact: string, newValue: boolean) {
    const oldState = checked.peek();
    const oldValue = oldState.contacts[contact] ?? false;
    // Here we intentionally don't update the contacts object. But it shouldn't be necessary since nothing should depend directly on it.
    // The count count should be used instead.
    oldState.contacts[contact] = newValue;
    const newCount = oldState.count - (oldValue ? 1 : 0) + (newValue ? 1 : 0);

    checked.value = { contacts: oldState.contacts, count: newCount };
}

function resetCheckedSignal(checked: Signal<CheckedContacts>, reset: Signal<EmptyObject>) {
    const newValue = getDefaultChecked();
    checked.value = newValue;
    reset.value = {};
}
