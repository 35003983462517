// This is kosher because this is the one use case in which the {} type actually means something.
// eslint-disable-next-line @typescript-eslint/ban-types
export type EmptyIntersection = {};
export type EmptyObject = Record<string, never>;

import { z } from 'zod';
import type { LocaleCode } from './i18n';

export type RequiredNonNull<T extends Record<string, unknown>> = {
    [key in keyof T]: NonNullable<T[key]>;
}

export function toUnique<T>(array: T[], toComparable?: (item: T) => string | number): T[] {
    if (!toComparable)
        return Array.from(new Set(array));

    const output: T[] = [];
    const set = new Set<string | number>();
    for (const item of array) {
        const comparable = toComparable(item);
        if (set.has(comparable))
            continue;

        set.add(comparable);
        output.push(item);
    }

    return output;
}

export function toRecord<TKey extends string, TType extends { [key in TKey]: string }>(key: TKey, array: TType[]): Record<string, TType> {
    const output: Record<string, TType> = {};
    for (const item of array)
        output[item[key]] = item;

    return output;
}

export function computeIfAbsent<TKey, TValue>(map: Map<TKey, TValue>, key: TKey, computeFunction: (key: TKey) => TValue): TValue;

export function computeIfAbsent<TKey, TValue>(map: Map<TKey, TValue>, key: TKey, computeFunction: (key: TKey) => TValue | undefined): TValue | undefined;

export function computeIfAbsent<TKey, TValue>(map: Map<TKey, TValue>, key: TKey, computeFunction: (key: TKey) => TValue): TValue {
    const currentElement = map.get(key);
    if (currentElement !== undefined)
        return currentElement;

    // Here it's important that the value type of the map shouldn't support undefined. If it could, we could get map like this:
    // { 'key1': 'value1', 'key2': undefined }
    // Everything will work, but the map will contain undefined values. When calling map.values(), we would get:
    // [ 'value1', undefined ]
    const newElement = computeFunction(key);
    if (newElement === undefined)
        return undefined as TValue;

    map.set(key, newElement);

    return newElement;
}

/**
 * Distributive omit - if T = A | B | C, then DOmit<T, K> = DOmit<A, K> | DOmit<B, K> | DOmit<C, K>
 */
export type DOmit<T, K extends keyof T> = T extends unknown ? Omit<T, K> : never;

/**
 * Distributive keyof
 */
export type DKeyof<T> = T extends unknown ? keyof T : never;

export type PartialBy<T, K extends keyof T> = Pick<Partial<T>, K> & DOmit<T, K>;

export type RequiredBy<T, K extends keyof T> = Pick<Required<T>, K> & DOmit<T, K>;

export enum SortOrder {
    Ascending = 'asc',
    Descending = 'desc',
}

export function optional<TKey extends string, TValue>(key: TKey, value: TValue | undefined): { [K in TKey]?: TValue } {
    return (value !== undefined ? { [key]: value } : {}) as { [K in TKey]?: TValue };
}

type EnumObject<K = string | number> = { [key: string]: K };
type Enum<K, E extends EnumObject<K>> = E extends { [key: string]: infer T | string } ? T : never;

/**
 * Returns the string values of the enum object. E.g., `enum ABC { A: 'a' }` will return `[ 'a' ]`.
 * But that's ok since these are exactly the values we want to use (it's the same as `ABC.A`).
 */
export function getEnumValues<E extends EnumObject<string>>(enumObject: E): Enum<string, E>[] {
    return Object.keys(enumObject).map(key => enumObject[key] as Enum<string, E>);
}

export function parseEnumValue<E extends EnumObject<string>>(value: string, enumObject: E): Enum<string, E> | undefined {
    return Object.values(enumObject).includes(value) ? value as Enum<string, E> : undefined;
}

export function zEnumRecord<E extends EnumObject<string>, V extends z.ZodTypeAny>(enumObject: E, valueSchema: V) {
    const keys = getEnumValues(enumObject);
    return z.record(z.nativeEnum(enumObject), valueSchema).refine((obj): obj is Record<E[keyof E], z.infer<V>> =>
        keys.every(key => obj[key as E[keyof E]] !== undefined),
    );
}

export type EnumFilter<E extends string> = {
    [key in E]: boolean;
};

export function enumFilterToArray<E extends string>(filter: EnumFilter<E>): E[] {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return Object.entries(filter).filter(([ , selected ]) => selected).map(([ key ]) => key as E);
}

export function isArrayOfType<T>(array: unknown[], discriminator: (element: unknown) => element is T): array is T[] {
    for (const element of array) {
        if (!discriminator(element))
            return false;
    }

    return true;
}

/** Lowercase hex (6-characters) without the '#' symbol. */
export const zColor = z.string().regex(/^[0-9a-f]{6}$/);

/**
 * Shortens a UUID v4 from 36 characters to 26 Base32hex characters (see RFC 4648).
 * Only lowercase letters are allowed.
 * Important note: Some other UUID representations might expect different Base32 alphabet.
 */
export function uuidToBase32(uuid: string): string {
    const withoutDashes = uuid.replace(/-/g, '');
    const base32 = base16ToBase32(withoutDashes);
    return base32.padStart(26, '0');
}

/**
 * Converts a Base16 string (0 - 9, a - f) to a Base32hex string (0 - 9, a - v). See RFC 4648.
 */
function base16ToBase32(input: string): string {
    // The input string might be just too long, so we have to split it into chunks.
    const chunksLength = Math.ceil(input.length / 5);
    const output = new Array(chunksLength);

    for (let i = 0; i < chunksLength; i++) {
        const end = input.length - i * 5;
        const start = end - 5;
        const fixedStart = start < 0 ? 0 : start;

        const chunk = input.slice(fixedStart, end);
        const base32 = parseInt(chunk, 16).toString(32);
        // The fist chunk is not padded.
        output[chunksLength - i - 1] = i !== chunksLength - 1 ? base32.padStart(4, '0') : base32;
    }

    return output.join('');
}

export function deepEquals<T extends object>(a: T, b: T): boolean {
    for (const key in a) {
        if (typeof a[key] === 'object' && a[key] !== null && b[key] !== null) {
            if (!deepEquals(a[key], b[key] as object))
                return false;
        }
        else if (a[key] !== b[key]) {
            return false;
        }
    }

    return true;
}

export function deepClone<T extends object & { [Symbol.iterator]?: never }>(o: T): T {
    const output: T = {} as T;
    for (const key in o)
        output[key] = deepCloneValue(o[key]);

    return output;
}

function deepCloneValue<T>(a: T): T {
    if (a === null || a === undefined)
        return a;

    if (typeof a === 'object') {
        if (Array.isArray(a))
            return a.map(deepCloneValue) as T;

        return deepClone(a);
    }

    return a;
}

/** Changes the first character of given string to upper case. */
export function capitalize(word: string) {
    return word.charAt(0).toUpperCase() + word.slice(1);
}

/** Removes diacritics and converts to lowercase. */
export function normalizeString(input: string): string {
    return input.normalize('NFD').replace(/\p{Diacritic}/gu, '').toLowerCase();
}

export function stringToQueryWords(input: string): string[] {
    return normalizeString(input).replace(/[^a-zA-Z0-9]+/gu, ' ').split(' ').filter(word => !!word);
}

/** This function should never fail, whatever the input string is. It always returns a non-empty string. If possible, a two-character string is returned. */
export function getShortcutFromString(input: string): string {
    const split = stringToQueryWords(input);
    return split.length > 1
        ? split[0][0].toUpperCase() + split[1][0].toUpperCase()
        : split.length === 1
            ? split[0].slice(0, 2).toUpperCase()
            // Fallback for whatever reason.
            : '#';
}

/** Replaces all sequences of special characters with '-' (there won't be two '-' next to each other). Also removes '-' from start and end. */
export function createSlug(input: string): string {
    return normalizeString(input)
        .replace(/[^a-zA-Z0-9]+/g, '-')
        .replace(/^-+|-+$/g, '');
}

/** min store slug length */
export const STORE_SLUG_MIN_LENGTH = 3;

export function isSlugValid(slug: string): boolean {
    return slug.match(/^[a-z0-9-]+$/) !== null && slug.match(/^-+|-+$/) === null;
}

export function isStoreSlugValid(slug: string): boolean {
    return isSlugValid(slug) && slug.length >= STORE_SLUG_MIN_LENGTH;
}

/**
 * Add https:// if no http[s]:// in the beginning.
 * @example <a href={linkify('flowlance.com')} target='_blank' rel='noreferrer'>
 */
export function linkify(input: string): string {
    if (input.startsWith('http://') || input.startsWith('https://'))
        return input;
    return `https://${input}`;
}

export const DEFAULT_STRING_MAX_LENGTH = 255;

export function zRequiredString(maxLength: number = DEFAULT_STRING_MAX_LENGTH) {
    return z.string().min(1, 'required').max(maxLength, `too-long_${maxLength}`);
}

export function zOptionalString(maxLength: number = DEFAULT_STRING_MAX_LENGTH) {
    return z.string().max(maxLength, `too-long_${maxLength}`).transform(value => value === '' ? undefined : value).optional();
}

/** Comparison of only ascii-like strings. */
export function compareStringsAscii(a: string, b: string): number {
    return a < b ? -1 : (a > b ? 1 : 0);
}

/** Language-independent comparison of rich strings. */
export function compareStringsUniversal(a: string, b: string): number {
    return a.localeCompare(b, 'en');
}

/**
 * Language specific comparison of strings. Use whenever user explicitly wants something alphabetically sorted.
 * Sorts in ascending order (a -> z -> A -> Z).
 */
export function compareStringsLocalized(a: string, b: string, locale: LocaleCode): number {
    return a.localeCompare(b, locale);
}

/**
 * Removes the '#' character (if it's there) and pads to 6 characters (if it's a 3 character hex).
 */
export function parseColor(color: string): string {
    const base = color.startsWith('#') ? color.slice(1) : color;
    return base.length === 3 ? base.repeat(2) : base;
}

/**
 * Returns true if the color is dark (i.e., the text should be white).
 * @param color Hex code (6 characters).
 */
export function isColorDark(color: string): boolean {
    // Based on https://stackoverflow.com/a/41491220.
    // There is also a more complex solution, but this one should be enough for now.
    return getColorLightness(color) < 186;
}

/**
 * Returns true if the color should have some kind of border or shadow to be visible on a white background.
 * @param color Hex code (6 characters).
 */
export function isColorTooLight(color: string): boolean {
    return getColorLightness(color) > 232;
}

function getColorLightness(color: string): number {
    const r = parseInt(color.slice(0, 2), 16);
    const g = parseInt(color.slice(2, 4), 16);
    const b = parseInt(color.slice(4, 6), 16);

    return r * 0.299 + g * 0.587 + b * 0.114;
}

/**
 * @param color Hex code (6 characters).
 * @param ratio From -1 (lighten) to 1 (darken).
 */
export function shadeColor(color: string, ratio: number): string {
    const isLighten = ratio < 0;
    return blendColors(Math.abs(ratio), isLighten ? 'ffffff' : '000000', color);
}

// Stolen from https://github.com/PimpTrizkit/PJs/wiki/12.-Shade,-Blend-and-Convert-a-Web-Color-(pSBC.js).
function blendColors(ratio: number, color0: string, color1: string, useLinear = false) {
    const c0 = getRGB(color0);
    const c1 = getRGB(color1);
    const blend = useLinear ? linearBlend : logBlend;

    const r = blend(c0.r, c1.r, ratio);
    const g = blend(c0.g, c1.g, ratio);
    const b = blend(c0.b, c1.b, ratio);

    const sum = 4294967296 + r * 16777216 + g * 65536 + b * 256;
    return sum.toString(16).slice(1, -2);
}

function getRGB(color: string): { r: number, g: number, b: number} {
    const number = parseInt(color, 16);

    return {
        r: number >> 16,
        g: number >> 8 & 255,
        b: number & 255,
    };
}

function linearBlend(color1: number, color2: number, ratio: number): number {
    return Math.round(ratio * color1 + (1 - ratio) * color2);
}

function logBlend(color1: number, color2: number, ratio: number): number {
    const sum = ratio * color1 * color1 + (1 - ratio) * color2 * color2;
    return Math.round(Math.sqrt(sum));
}
