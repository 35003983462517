import { priceToServer, type Money, type TaxRateFE } from ':utils/money';
import { type Id } from ':utils/id';
import type { OrderItemFE } from './OrderItem';
import { type DateTime } from 'luxon';
import { type EventFE } from '../Event';
import { type ExceptScheduler } from '../Team';
import { ClientInfoFE } from '../Client';
import type { TeamMemberRole } from ':utils/entity/team';
import { EventState } from ':utils/entity/event';
import type { OrderItemEdit, ProductOrderItemOutput } from ':utils/entity/orderItem';
import { ProductType } from ':utils/entity/product';
import { toNumber } from ':utils/math';

type IfTrue<TCond, TRes> = TCond extends true ? TRes : undefined;

export class ProductOrderItemFE<TRole extends TeamMemberRole = typeof TeamMemberRole.master | typeof TeamMemberRole.freelancer, TSchedulable extends boolean = boolean> implements OrderItemFE<TRole> {
    private constructor(
        /** OrderItem.id, not ProductOrderItem.id */
        readonly id: Id,
        readonly title: string,
        readonly quantity: number,
        readonly unitPrice: ExceptScheduler<TRole, Money>,
        readonly vat: ExceptScheduler<TRole, TaxRateFE>,
        readonly index: number,
        readonly createdAt: DateTime,

        readonly type: ProductType,

        readonly sessionsCount: IfTrue<TSchedulable, number>,
        /** In seconds. */
        readonly sessionsDuration: IfTrue<TSchedulable, number>,
        readonly scheduledCount: IfTrue<TSchedulable, number>,
        readonly completedCount: IfTrue<TSchedulable, number>,
        readonly guest: IfTrue<TSchedulable, ClientInfoFE>,
    ) {}

    static fromServer<TRole extends TeamMemberRole>(base: OrderItemFE<TRole>, input: ProductOrderItemOutput) {
        const schedulable = input.type === ProductType.Session || input.type === ProductType.Bundle;
        if (schedulable) {
            return new ProductOrderItemFE<TRole, true>(
                base.id,
                base.title,
                base.quantity,
                base.unitPrice,
                base.vat,
                base.index,
                base.createdAt,
                input.type,
                input.type === ProductType.Bundle ? input.sessionsCount : 1,
                input.sessionsDuration,
                input.scheduledCount,
                input.completedCount,
                ClientInfoFE.fromServer(input.guest),
            );
        }
        else {
            return new ProductOrderItemFE<TRole, false>(
                base.id,
                base.title,
                base.quantity,
                base.unitPrice,
                base.vat,
                base.index,
                base.createdAt,
                input.type,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
            );
        }
    }

    static isCompleted(item: GenericProductItem<true>): boolean {
        return item.completedCount === item.sessionsCount;
    }

    static isScheduled(item: GenericProductItem<true>): boolean {
        return item.scheduledCount === item.sessionsCount;
    }

    static updateFromEvents<TRole extends TeamMemberRole>(item: ProductOrderItemFE<TRole, true>, events: EventFE[]): ProductOrderItemFE<TRole, true> {
        return new ProductOrderItemFE<TRole, true>(
            item.id,
            item.title,
            item.quantity,
            item.unitPrice,
            item.vat,
            item.index,
            item.createdAt,
            item.type,
            item.sessionsCount,
            item.sessionsDuration,
            item.scheduledCount + events.length,
            item.completedCount + events.filter(e => e.state === EventState.finished).length,
            item.guest,
        );
    }
}

export type GenericProductItem<TSchedulable extends boolean = boolean> = ProductOrderItemFE<TeamMemberRole, TSchedulable>;
export type SchedulerProductItem<TSchedulable extends boolean = boolean> = ProductOrderItemFE<typeof TeamMemberRole.scheduler, TSchedulable>;

export function productItemUpdateToServer(input: EditableProductItem): OrderItemEdit {
    return {
        id: input.id,
        title: input.title,
        quantity: 1,
        unitPrice: priceToServer(toNumber(input.unitPrice)),
        vat: input.vat.id,
        isDeleted: input.isDeleted ? true : undefined,
    };
}

export type EditableProductItem = {
    readonly id: string;
    readonly type: ProductType;
    readonly title: string;
    readonly sessionsCount?: number;
    readonly sessionsDuration?: number;
    // for ProductOrderItems the quantity is always 1
    // quantity: number | '';
    unitPrice: number | '' | '-';
    vat: TaxRateFE;
    isDeleted: boolean;
};

export function isProductItemEqual(form: EditableProductItem, item: ProductOrderItemFE): boolean {
    return !form.isDeleted
        && form.id === item.id
        && form.type === item.type
        && form.title === item.title
        && form.unitPrice === item.unitPrice.amount
        && form.vat === item.vat;
}
