import { useCallback, useMemo, useState } from 'react';
import { DefaultFilterItemBadge, type Filter, type FilterDefinition, type FilterFunction, type FilterItemBadgeProps, type FilterMenuProps } from './FilterRow';
import { DropdownMenu } from ':components/shadcn';
import { useTranslation } from 'react-i18next';
import { type ClientInfoFE } from ':frontend/types/Client';
import { type OrderInfoFE } from ':frontend/types/orders/Order';
import { ClientSelect } from ':frontend/components/client/ClientSelect';
import { compareArrays } from ':frontend/utils/common';
import { type Id } from ':utils/id';

export const filterName = 'orderClient';

type FilterState = {
    selected: ClientInfoFE[];
    all: ClientInfoFE[];
};

function FilterToggleMenu({ state, setState }: FilterMenuProps<FilterState>) {
    return (
        <DropdownMenu.Item className='p-4 bg-white'>
            <InnerFilter state={state} setState={setState} />
        </DropdownMenu.Item>
    );
}

function FilterRowMenu({ state, setState }: FilterMenuProps<FilterState>) {
    return (
        <div className='w-full flex lg:flex-1 justify-end'>
            <div className='w-full lg:max-w-[300px]'>
                <InnerFilter state={state} setState={setState} />
            </div>
        </div>
    );
}

function InnerFilter({ state, setState }: FilterMenuProps<FilterState>) {
    const availableClients = useMemo(() => state.all.filter(client => !state.selected.find(c => c.id === client.id)), [ state ]);
    const [ value, setValue ] = useState<ClientInfoFE>();

    const handleOnChange = useCallback((client?: ClientInfoFE) => {
        if (!client)
            return;

        setState({ ...state, selected: [ ...state.selected, client ] });
        setValue(undefined);
    }, [ state, setState ]);

    return (
        <ClientSelect
            value={value}
            clients={availableClients}
            onChange={handleOnChange}
            size='compact'
        />
    );
}

function FilterItemBadge({ item, onClose }: FilterItemBadgeProps<ClientInfoFE>) {
    const { t } = useTranslation('common', { keyPrefix: `filters.${filterName}` });

    return (
        <DefaultFilterItemBadge item={item} onClose={onClose}>
            <span className='max-w-40 truncate'>
                {t('badge-label', { name: item.name })}
            </span>
        </DefaultFilterItemBadge>
    );
}

function remove(state: FilterState, item: ClientInfoFE): FilterState {
    return {
        ...state,
        selected: state.selected.filter(client => client.id !== item.id),
    };
}

function toItems(state: FilterState): ClientInfoFE[] {
    return state.selected;
}

function createFilterFunction(state: FilterState): FilterFunction<OrderInfoFE> {
    if (state.selected.length === 0)
        return () => true;

    const set = new Set<string>(state.selected.map(client => client.id));

    return (data: OrderInfoFE) => set.has(data.client.id);
}

function toServer(state: FilterState, previous: Id[] | undefined): Id[] | undefined {
    const current = state.selected.map(client => client.id).sort();
    if (current.length === 0)
        return;

    return previous && compareArrays(current, previous)
        ? previous
        : current;
}

function createFilter(allClients: ClientInfoFE[]): FilterDefinition<ClientInfoFE, FilterState, OrderInfoFE> {
    return {
        name: filterName,
        defaultValues: {
            all: allClients,
            selected: [],
        },
        FilterToggleMenu,
        FilterRowMenu,
        FilterItemBadge,
        remove,
        toItems,
        createFilterFunction,
        toServer: toServer as (state: FilterState, previous: unknown | undefined) => Id[],
    };
}

export default createFilter as (allClients: ClientInfoFE[]) => Filter;
