import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Button, Form } from ':components/shadcn';
import { useAuth } from ':frontend/context/AuthProvider';
import { SpinnerButton } from ':frontend/components/common';
import { RHFErrorMessage } from ':frontend/components/forms/ErrorMessage';
import { useNavigationAction, type NavigationProperty } from ':frontend/hooks';
import { routesFE } from ':utils/routes';
import { GoogleOauthButton } from ':frontend/components/auth/googleButton';
import { PasswordInput } from ':frontend/components/forms/PasswordInput';
import useNotifications from ':frontend/context/NotificationProvider';
import { createErrorAlert } from ':frontend/components/notifications';
import { Visibility } from ':frontend/types/notifications';
import { trpc } from ':frontend/context/TrpcProvider';
import { flowlanceIcons } from ':components/icons/logos';

export type PrefillEmail = NavigationProperty<'prefillEmail', string>;

type LoginFormData = {
    email: string;
    password: string;
};

export default function Login() {
    const { t } = useTranslation('pages', { keyPrefix: 'login' });
    const trpcUtils = trpc.useUtils();
    const navigate = useNavigate();
    const [ isFetching, setIsFetching ] = useState(false);
    const [ isEmailOpen, setIsEmailOpen ] = useState(false);
    const prefilledEmail = useNavigationAction<PrefillEmail>('prefillEmail')?.data;

    const { auth } = useAuth();
    const { addAlert } = useNotifications();

    const postLogin = async (data: LoginFormData) => {
        setIsFetching(true);
        const result = await auth.loginWithEmail(data.email, data.password);
        await trpcUtils.invalidate();
        setIsFetching(false);
        if (!result.status)
            addAlert(createErrorAlert(result.error), Visibility.Unauthorized);
    };

    return (
        <div className='min-w-80 h-screen w-screen bg-secondary-50 flex md:items-center md:justify-center overflow-auto'>
            <div className='flex flex-col max-w-5xl z-20 md:space-x-20 md:flex-row'>
                <div className='order-2 md:order-1 md:w-1/2 p-10 md:p-0 flex flex-col justify-center gap-6 bg-secondary-50 rounded-t-xl shadow-[0px_-25px_35px_0px_rgba(0,0,0,0.08)] md:shadow-none md:bg-transparent'>
                    <flowlanceIcons.FlowlanceBanner />

                    <h1 className='text-2xl font-semibold leading-8 md:text-4xl md:leading-10'>{t('page-title')}</h1>

                    {!isEmailOpen && (<>
                        <GoogleOauthButton type='login' variant='primary' />

                        <div className='text-lg'>{t('or-label')}</div>

                        <div className='space-y-2'>
                            <Button className='w-full bg-transparent' variant='outline' onClick={() => setIsEmailOpen(true)}>{t('email-and-password')}</Button>
                            <Button className='w-full bg-transparent' variant='outline' onClick={() => navigate(routesFE.register.claimUrl)}>{t('register-link')}</Button>
                        </div>
                    </>)}

                    {isEmailOpen && (<>
                        <LoginEmailForm
                            onSubmit={postLogin}
                            isFetching={isFetching}
                            prefilledEmail={prefilledEmail}
                        />

                        <div className='text-lg'>{t('or-label')}</div>

                        <div className='space-y-2'>
                            <GoogleOauthButton type='login' variant='outline' />
                            <Button className='w-full bg-transparent' variant='outline' onClick={() => navigate(routesFE.register.claimUrl)}>
                                {t('register-link')}
                            </Button>
                        </div>
                    </>)}
                </div>

                <div className='flex justify-center order-1 h-[220px] py-4 md:order-2 md:h-auto md:w-1/2'>
                    <img src='/static/images/login/login-store-mockup.svg' className='h-fit w-[240px] md:w-[375px] drag-none' />
                </div>
            </div>

            <div className='max-md:hidden w-96 h-96 rounded-full bg-primary-500 blur-[150px] fixed -bottom-10 -right-10' />
        </div>
    );
}

type LoginEmailFormProps = Readonly<{
    onSubmit: (output: LoginFormData) => void;
    isFetching: boolean;
    prefilledEmail?: string;
}>;

function LoginEmailForm({ onSubmit, isFetching, prefilledEmail }: LoginEmailFormProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'login' });
    const { t: tf } = useTranslation('common', { keyPrefix: 'form' });
    const { register, handleSubmit, formState: { errors } } = useForm<LoginFormData>();
    const navigate = useNavigate();

    return (
        <Form.Root onSubmit={handleSubmit(onSubmit)} className='space-y-2'>
            <div className=''>
                <Form.Input
                    className='text-lg md:text-base'
                    label={t('email-label')}
                    type='email'
                    {...register('email', { required: tf('email-required') })}
                    defaultValue={prefilledEmail}
                    autoFocus
                />
                <RHFErrorMessage errors={errors} name='email' />
            </div>
            <div className=''>
                <PasswordInput
                    className='text-lg md:text-base'
                    label={t('password-label')}
                    register={register('password', { required: tf('password-required') })}
                />
                <RHFErrorMessage errors={errors} name='password' />
            </div>
            <div className='space-y-2'>
                <SpinnerButton
                    type='submit'
                    variant='primary'
                    className='w-full'
                    isFetching={isFetching}
                >
                    {t('login-button')}
                </SpinnerButton>
                <div className='flex justify-end'>
                    <Button size='small' className='bg-transparent' variant='outline' onClick={() => navigate(routesFE.resetPassword)}>
                        {t('reset-password-link')}
                    </Button>
                </div>
            </div>
        </Form.Root>
    );
}
