import { useEffect, useReducer, type Dispatch, useMemo } from 'react';
import { getClientIdentifier } from ':frontend/types/EventParticipant';
import { useMaster, type MasterContext } from ':frontend/context/UserProvider';
import useNotifications, { type AddAlertFunction } from ':frontend/context/NotificationProvider';
import { createErrorAlert, createTranslatedErrorAlert, createTranslatedSuccessAlert } from '../notifications';
import { OrderFE, type OrderSync, type OrderUpdate, type OrderDoTransition, orderEditToServer, SyncType, DEFAULT_PREFIX, type EditableOrderFields, type OrderEditFE, type ChargeError, type NumberAlreadyUsedError, isChargeError, isNumberAlreadyUsedError, type OrderSendNotification, notificationToServer, type OrderTransitionFE } from ':frontend/types/orders/Order';
import { useNavigate, type NavigateFunction } from 'react-router-dom';
import type { InvoicingIdentityFE } from ':frontend/types/Invoicing';
import { defaultEditableAddress, type EditableAddress, isAddressEqual } from ':frontend/types/Address';
import { type TypedAction, ifChanged } from ':frontend/utils/common';
import type { RequiredBy } from ':utils/common';
import { type DateTime } from 'luxon';
import { isEventItemEqual, type EditableEventItem } from ':frontend/types/orders/EventOrderItem';
import { isCustomItemEqual, type EditableCustomItem } from ':frontend/types/orders/CustomOrderItem';
import { routesFE } from ':utils/routes';
import { type FormErrors, Updator, type RulesDefinition, type FormPath } from ':frontend/utils/updator';
import { type EmailPreviewAction, emailPreviewReducer, type EmailPreviewState, computeInitialEmailPreviewState, validateEmailPreview, parseCcEmails } from './checkout/useCheckout';
import { generateVariableSymbol, type OrderCustomFields } from ':utils/entity/order';
import { trpc } from ':frontend/context/TrpcProvider';
import { isProductItemEqual, type EditableProductItem } from ':frontend/types/orders/ProductOrderItem';
import { secondsToMinutes } from 'date-fns';

export function useOrder(input: OrderFE) {
    const masterContext = useMaster();
    const [ state, dispatch ] = useReducer(orderReducer, computeInitialState(input, masterContext));

    // When the user context changes, we have to actualise it.
    useEffect(() => {
        dispatch({ type: 'masterContext', masterContext });
    }, [ masterContext ]);

    const { addAlert } = useNotifications();
    const navigate = useNavigate();

    const updateOrderMutation = trpc.order.updateOrder.useMutation();
    const transitionOrderMutation = trpc.order.transitionOrder.useMutation();
    const deleteOrderMutation = trpc.order.deleteOrder.useMutation();
    const sendNotificationMutation = trpc.order.sendNotification.useMutation();

    const syncFunctions = useMemo(() => new SyncFunctions(dispatch, addAlert, navigate), [ addAlert, navigate ]);

    useEffect(() => {
        if (!state.sync?.fetching)
            return;

        const sync = state.sync.orderSync;

        switch (sync.type) {
        case SyncType.Update:
            syncFunctions.update(updateOrderMutation, sync, state.order);
            break;
        case SyncType.Transition:
            syncFunctions.transition(transitionOrderMutation, sync, state.order);
            break;
        case SyncType.Delete:
            syncFunctions.delete(deleteOrderMutation, state.order);
            break;
        case SyncType.SendNotification:
            syncFunctions.sendNotification(sendNotificationMutation, sync, state.order);
            break;
        }
    }, [ !!state.sync?.fetching ]);

    return {
        state,
        dispatch,
    };
}

class SyncFunctions {
    constructor(
        private readonly dispatch: UseOrderDispatch,
        private readonly addAlert: AddAlertFunction,
        private readonly navigate: NavigateFunction,
    ) {}

    update(mutation: ReturnType<typeof trpc.order.updateOrder.useMutation>, action: OrderUpdate, order: OrderFE) {
        const edit = orderEditToServer(action);
        mutation.mutate({ id: order.id, ...edit }, {
            onError: error => {
                if (isChargeError(error.data)) {
                    this.addAlert(createErrorAlert(error.data));
                    this.dispatch({ type: 'error', operation: 'charge', value: error.data });
                    return;
                }
                if (isNumberAlreadyUsedError(error.data)) {
                    this.dispatch({ type: 'error', operation: 'numberAlreadyUsed', value: error.data });
                    return;
                }

                this.addAlert(createTranslatedErrorAlert());
                this.dispatch({ type: 'error', operation: 'other', value: error.data });
            },
            onSuccess: response => {
                this.addAlert(createTranslatedSuccessAlert('pages:orderDetail.update-success'));
                this.dispatch({ type: 'sync', operation: 'finish', order: OrderFE.fromServer(response) });
            },
        });
    }

    transition(mutation: ReturnType<typeof trpc.order.transitionOrder.useMutation>, action: OrderDoTransition, order: OrderFE) {
        mutation.mutate({ id: order.id, transition: action.data.transition }, {
            onError: error => {
                // Here we should probably reload the whole page, because the error is likely caused by the user being out of sync.
                // Also, the user can't be editing the order now.
                this.addAlert(createTranslatedErrorAlert());
                this.dispatch({ type: 'error', operation: 'other', value: error.data });
            },
            onSuccess: response => {
                this.addAlert(createTranslatedSuccessAlert(`pages:orderDetail.${action.data.transition}-success`));
                this.dispatch({ type: 'sync', operation: 'finish', order: OrderFE.fromServer(response) });
            },
        });
    }

    delete(mutation: ReturnType<typeof trpc.order.deleteOrder.useMutation>, order: OrderFE) {
        mutation.mutate({ id: order.id }, {
            onError: error => {
                this.addAlert(createErrorAlert(error.data));
            },
            onSuccess: () => {
                this.addAlert(createTranslatedSuccessAlert('pages:orderDetail.deleteOrderModal.success'));
                this.navigate(routesFE.orders.list.path);
            },
        });
    }

    sendNotification(mutation: ReturnType<typeof trpc.order.sendNotification.useMutation>, action: OrderSendNotification, order: OrderFE) {
        mutation.mutate({ orderId: order.id, notification: notificationToServer(action.data) }, {
            onError: error => {
                // TODO Enable the user to close the modal.
                this.addAlert(createErrorAlert(error.data));
            },
            onSuccess: response => {
                // TODO Once sent, this will be always success.
                const newOrder = OrderFE.fromServer(response);
                const alert = newOrder.isNotificationSent
                    ? createTranslatedSuccessAlert('pages:orderDetail.sendNotificationModal.success')
                    : createTranslatedErrorAlert('pages:orderDetail.sendNotificationModal.failure-alert');
                this.addAlert(alert);
                this.dispatch({ type: 'sync', operation: 'finish', order: newOrder });
            },
        });
    }
}

export type UseOrderState = {
    masterContext: MasterContext;
    /** The current order that is saved on BE. It should be updated whenever we synchronize with BE. */
    order: OrderFE;
    form?: OrderFormState;
    formErrors?: FormErrors;
    sync?: SyncState;
    error?: ErrorState;
    sendNotification?: {
        phase: 'overview' | 'emailPreview';
        emailPreview: EmailPreviewState;
    };
}

function computeInitialState(order: OrderFE, masterContext: MasterContext): UseOrderState {
    return {
        masterContext,
        order,
    };
}

export type UseOrderDispatch = Dispatch<OrderAction>;

type OrderAction = ResetAction | UserContextAction | FormAction | InputAction | SyncAction | ErrorAction | SendNotificationAction;

function orderReducer(state: UseOrderState, action: OrderAction): UseOrderState {
    console.log('Reduce:', state, action);

    switch (action.type) {
    case 'reset': return computeInitialState(action.order ?? state.order, state.masterContext);
    case 'masterContext': return { ...state, masterContext: action.masterContext };
    case 'form': return form(state, action);
    case 'input': return input(state, action);
    case 'sync': return sync(state, action);
    case 'error': return error(state, action);
    case 'sendNotification':
    case 'emailPreview':
        return sendNotification(state, action);
    }
}

// Reset

type ResetAction = TypedAction<'reset', {
    order?: OrderFE;
}>;

// UserContext

type UserContextAction = TypedAction<'masterContext', {
    type: 'masterContext';
    masterContext: MasterContext;
}>;

// Form

export type OrderFormState = {
    issueDate: DateTime;
    dueDate: DateTime;
    taxDate: DateTime;
    isCondensedInvoice: boolean;
    supplier: EditableInvoicingIdentity;
    subscriber: EditableInvoicingIdentity;
    customFields: EditableOrderFields;
    productItems: EditableProductItem[];
    eventItems: EditableEventItem[];
    customItems: EditableCustomItem[];
    invoice?: {
        /** This value has the last hyphen removed so that it can be displayed independently. */
        formPrefix: string;
        index: number;
        variableSymbol: string;
        generatedVariableSymbol: string;
    };
};

export function itemsDeletable(form: OrderFormState): boolean {
    const items = [ ...form.eventItems, ...form.productItems, ...form.customItems ];
    const notDeletedCount = items.reduce((count, item) => item.isDeleted ? count : count + 1, 0);
    return notDeletedCount > 1;
}

export type OrderFormWithInvoice = RequiredBy<OrderFormState, 'invoice'>;
export function hasInvoice(form: OrderFormState): form is OrderFormWithInvoice {
    return !!form.invoice;
}

type EditableInvoicingIdentity = {
    email: string;
    name: string;
    address: EditableAddress;
    cin: string;
    tin: string;
};

type FormAction = TypedAction<'form', {
    operation: 'edit' | 'discard' | 'generateVariableSymbol';
}>;

function form(state: UseOrderState, action: FormAction): UseOrderState {
    if (action.operation === 'discard')
        return { ...state, form: undefined, formErrors: undefined };

    if (action.operation === 'generateVariableSymbol') {
        return state.form?.invoice
            ? { ...state, form: { ...state.form, invoice: { ...state.form.invoice, variableSymbol: state.form.invoice.generatedVariableSymbol } } }
            : state;
    }

    const { order } = state;

    const form: OrderFormState = {
        issueDate: order.issueDate,
        dueDate: order.dueDate,
        taxDate: order.taxDate,
        isCondensedInvoice: order.isCondensedInvoice,
        supplier: identityToForm(order.supplier),
        subscriber: identityToForm(order.subscriber),
        customFields: fieldsToForm(order.fields),
        productItems: order.getProductItems().map(item => ({
            id: item.id,
            type: item.type,
            title: item.title,
            sessionsCount: item.sessionsCount,
            sessionsDuration: item.sessionsDuration === undefined ? undefined : secondsToMinutes(item.sessionsDuration),
            quantity: item.quantity,
            unitPrice: item.unitPrice.amount,
            vat: item.vat,
            isDeleted: false,
        })),
        eventItems: order.getEventItems().map(item => ({
            id: item.id,
            event: item.event,
            title: item.title,
            unitPrice: item.unitPrice.amount,
            quantity: item.quantity,
            vat: item.vat,
            isDeleted: false,
        })),
        customItems: order.getCustomItems().map(item => ({
            id: item.id,
            title: item.title,
            quantity: item.quantity,
            unitPrice: item.unitPrice.amount,
            vat: item.vat,
            isDeleted: false,
        })),
        invoice: createFormInvoice(state),
    };

    return { ...state, form };
}

function createFormInvoice({ order, masterContext }: UseOrderState): OrderFormState['invoice'] | undefined {
    if (!order.invoice)
        return undefined;

    const parsedPrefix = parsePrefix(order.invoice.prefix, masterContext.subscription.restrictions.invoicing.customLogo);
    const formPrefix = parsedPrefix.slice(0, -1);

    return {
        formPrefix,
        index: order.invoice.index,
        variableSymbol: order.invoice.variableSymbol,
        generatedVariableSymbol: generateVariableSymbol(order.issueDate, order.invoice.index),
    };
}

function parsePrefix(prefix: string, isCustomEnabled: boolean): string {
    if (isCustomEnabled)
        return prefix;

    return prefix.startsWith(DEFAULT_PREFIX) ? prefix.slice(DEFAULT_PREFIX.length) : prefix;
}

function identityToForm(input: InvoicingIdentityFE): EditableInvoicingIdentity {
    return {
        name: input.name,
        email: input.email ?? '',
        address: input.address?.toEditable() ?? defaultEditableAddress,
        cin: input.cin ?? '',
        tin: input.tin ?? '',
    };
}

function fieldsToForm(input: OrderCustomFields): EditableOrderFields {
    return {
        header: input.header ?? '',
        footer: input.footer ?? '',
        customKey1: input.customKey1 ?? '',
        customValue1: input.customValue1 ?? '',
        customKey2: input.customKey2 ?? '',
        customValue2: input.customValue2 ?? '',
    };
}

// Input

type InputAction = TypedAction<'input', {
    field: FormPath<OrderFormState>;
    value: unknown;
}>;

function input(state: UseOrderState, action: InputAction): UseOrderState {
    if (!state.form)
        return state;

    const { form, formErrors } = Updator.update(state as RequiredBy<UseOrderState, 'form'>, action.field, action.value, rules);
    if ((action.field === 'issueDate' || action.field === 'invoice.index') && hasInvoice(form))
        form.invoice.generatedVariableSymbol = generateVariableSymbol(form.issueDate, form.invoice.index);

    return { ...state, form, formErrors };
}

const rules: RulesDefinition<OrderFormState> = {
    supplier: {
        name: (value: unknown) => value !== '' || 'common:form.name-company-required',
    },
    subscriber: {
        name: (value: unknown) => value !== '' || 'common:form.name-company-required',
    },
    eventItems: {
        title: (value: unknown) => value !== '' || 'common:form.title-required',
    },
    customItems: {
        title: (value: unknown) => value !== '' || 'common:form.title-required',
    },
};

// Synchronization

export type SyncState = {
    /** The order is being saved. The request is fired when this becomes true. This is an identifier of the button that caused the fetch. */
    fetching?: string;
    orderSync: OrderSync;
};

type SyncAction = TypedAction<'sync', {
    operation: 'save' | 'delete';
    fid?: string;
} | {
    operation: 'transition';
    transition: OrderTransitionFE;
    fid?: string;
} | {
    operation: 'sendNotification';
    fid?: string;
} | {
    operation: 'finish';
    order: OrderFE;
}>;

function sync(state: UseOrderState, action: SyncAction): UseOrderState {
    if (action.operation === 'finish')
        return computeInitialState(action.order, state.masterContext);

    return { ...state, sync: { orderSync: createSyncObject(state, action), fetching: action.fid } };
}

function createSyncObject(state: UseOrderState, action: SyncAction): OrderSync {
    if (action.operation === 'delete')
        return { type: SyncType.Delete, data: undefined };
    if (action.operation === 'transition')
        return { type: SyncType.Transition, data: { transition: action.transition } };
    if (action.operation === 'sendNotification') {
        if (!state.sendNotification)
            throw new Error('Invalid state');

        const emailPreview = state.sendNotification.emailPreview;
        const data = { ...emailPreview.form, cc: parseCcEmails(emailPreview.form.cc) };

        return { type: SyncType.SendNotification, data };
    }

    return {
        type: SyncType.Update,
        data: createEditObject(state),
    };
}

// TODO This should be in the orderEditToServer function. Or the other way around. There is no reason to have this in two places.
function createEditObject({ order, form, masterContext }: UseOrderState): OrderEditFE {
    if (!form)
        throw new Error('Invalid order state');

    const originalEventItems = order.getEventItems();
    const originalCustomItems = order.getCustomItems();
    const originalProductItems = order.getProductItems();

    return {
        issueDate: ifChanged(form.issueDate, order.issueDate),
        dueDate: ifChanged(form.dueDate, order.dueDate),
        taxDate: ifChanged(form.taxDate, order.taxDate),
        isCondensedInvoice: ifChanged(form.isCondensedInvoice, order.isCondensedInvoice),
        supplier: ifChanged(form.supplier, order.supplier, isInvoicingIdentityEqual),
        subscriber: ifChanged(form.subscriber, order.subscriber, isInvoicingIdentityEqual),
        fields: ifChanged(form.customFields, order.fields, isOrderFieldsEqual),
        eventItems: form.eventItems.filter((item, index) => !isEventItemEqual(item, originalEventItems[index])),
        customItems: form.customItems.filter((item, index) => !isCustomItemEqual(item, originalCustomItems[index])),
        productItems: form.productItems.filter((item, index) => !isProductItemEqual(item, originalProductItems[index])),
        ...createInvoiceEditObject(order, form, masterContext),
    };
}

function createInvoiceEditObject(order: OrderFE, form: OrderFormState, masterContext: MasterContext): { prefix?: string, index?: number, variableSymbol?: string } {
    if (!order.invoice || !hasInvoice(form))
        return {};

    const fullPrefix = computeFullPrefix(form, masterContext);
    return {
        prefix: order.invoice && ifChanged(fullPrefix, order.invoice.prefix),
        index: order.invoice && ifChanged(form.invoice.index, order.invoice.index),
        variableSymbol: ifChanged(form.invoice.variableSymbol, order.invoice.variableSymbol),
    };
}

export function computeFullPrefix(form: OrderFormWithInvoice, masterContext: MasterContext): string {
    const isCustomEnabled = masterContext.subscription.restrictions.invoicing.customLogo;
    return (isCustomEnabled ? '' : DEFAULT_PREFIX) + form.invoice.formPrefix + '-';
}

// TODO create a local copy of the form data and compare it with the form (instead of the original order).
function isInvoicingIdentityEqual(form: EditableInvoicingIdentity, identity: InvoicingIdentityFE): boolean {
    const a = form.name === (identity.name ?? '')
        && form.email === (identity.email ?? '')
        && isAddressEqual(form.address, identity.address ?? defaultEditableAddress)
        && form.cin === (identity.cin ?? '')
        && form.tin === (identity.tin ?? '');

    return a;
}

function isOrderFieldsEqual(form: EditableOrderFields, fields: OrderCustomFields): boolean {
    return form.header === (fields.header ?? '')
        && form.footer === (fields.footer ?? '')
        && form.customKey1 === (fields.customKey1 ?? '')
        && form.customValue1 === (fields.customValue1 ?? '')
        && form.customKey2 === (fields.customKey2 ?? '')
        && form.customValue2 === (fields.customValue2 ?? '');
}

// Error

type ErrorState = {
    charge?: ChargeError;
    numberAlreadyUsed?: NumberAlreadyUsedError;
    /** Fallback for all other errors. */
    other?: unknown;
};

type ErrorAction = TypedAction<'error', {
    operation: 'reset';
} | {
    operation: 'charge';
    value: ChargeError | undefined;
} | {
    operation: 'numberAlreadyUsed';
    value: NumberAlreadyUsedError | undefined;
} | {
    operation: 'other';
    value: unknown | undefined;
}>;

function error(state: UseOrderState, action: ErrorAction): UseOrderState {
    if (action.operation === 'reset')
        return { ...state, error: undefined };

    return createErrorState(state, { [action.operation]: action.value } );
}

function createErrorState(state: UseOrderState, error: ErrorState): UseOrderState {
    // In any case except reset, we want to stop the current sync processes.
    return { ...state, sync: undefined, error };
}

// Send notification

type SendNotificationAction = EmailPreviewAction | TypedAction<'sendNotification', {
    operation: 'open' | 'close' | 'overview' | 'emailPreview';
}>;

function sendNotification(state: UseOrderState, action: SendNotificationAction): UseOrderState {
    const sendNotification = state.sendNotification;

    if (action.type === 'emailPreview') {
        if (!sendNotification)
            throw new Error('Invalid state');

        const emailPreview = emailPreviewReducer(sendNotification.emailPreview, action);
        return { ...state, sendNotification: { ...sendNotification, emailPreview } };
    }

    if (action.operation === 'open') {
        // Kinda verbose, but it's the easiest way to pass the clients to the email preview.
        const clients = [ { info: state.order.client, identifier: getClientIdentifier(state.order.client) } ];
        const emailPreview = computeInitialEmailPreviewState(clients, state.masterContext);
        return { ...state, sendNotification: { phase: 'overview', emailPreview } };
    }

    if (action.operation === 'close')
        // If we are fetching, we want to keep the modal open so that the user see the fetching progress.
        return state.sync?.fetching ? state : { ...state, sendNotification: undefined };

    if (!sendNotification)
        throw new Error('Invalid state');

    if (action.operation === 'emailPreview') {
        const emailPreview = { ...sendNotification.emailPreview, formErrors: undefined, wasSubmitted: false };
        return { ...state, sendNotification: { ...sendNotification, emailPreview, phase: 'emailPreview' } };
    }

    const emailPreview = validateEmailPreview(sendNotification.emailPreview);
    const phase = emailPreview.formErrors ? sendNotification.phase : 'overview';
    return { ...state, sendNotification: { ...sendNotification, emailPreview, phase } };
}
