import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link,  useNavigate } from 'react-router-dom';
import { routesFE } from ':utils/routes';
import { useAuth } from ':frontend/context/AuthProvider';
import { ACCESS_TOKENS_COOKIE_NAME, zAccessTokens } from ':utils/auth';
import Cookies from 'js-cookie';
import { flowlanceIcons } from ':components/icons/logos';

export default function RegisterGoogleCallback() {
    const { t } = useTranslation('pages', { keyPrefix: 'registerGoogle' });
    const { auth } = useAuth();

    const navigate = useNavigate();

    useEffect(() => {
        const cookieTokens = Cookies.get(ACCESS_TOKENS_COOKIE_NAME);
        if (!cookieTokens)
            return;

        const tokens = zAccessTokens.parse(JSON.parse(cookieTokens));
        Cookies.remove(ACCESS_TOKENS_COOKIE_NAME);

        auth.loginWithTokens(tokens);
        navigate(routesFE.root);
    }, []);

    return (
        <div className='min-w-80 h-screen w-screen bg-secondary-50 flex md:items-center md:justify-center overflow-auto'>
            <div className='w-full md:w-[400px] space-y-4 p-10 md:p-0'>
                <div className='flex flex-col justify-center gap-8 h-full'>
                    <flowlanceIcons.FlowlanceBanner />

                    <h1 className='text-2xl font-semibold leading-8 md:text-4xl md:leading-10'>{t('something-went-wrong')}</h1>

                    <p className='text-lg'>
                        <Trans
                            i18nKey='try-again'
                            t={t}
                            components={{
                                a: <Link to={routesFE.register.claimUrl} />,
                            }}
                        />
                    </p>
                </div>
            </div>

            <div className='max-md:hidden w-96 h-96 rounded-full bg-primary-500 blur-[150px] fixed -bottom-10 -right-10' />
        </div>
    );
}
