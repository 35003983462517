import { ArrowLeftIcon, Eye2Icon, TrashXmarkIcon } from ':components/icons/basic';
import { Button, Modal } from ':components/shadcn';
import { BlockNavigationModal } from ':frontend/components/BlockNavigationModal';
import { SpinnerButton } from ':frontend/components/common';
import { Content, Topbar, TopHeader } from ':frontend/components/Layout';
import { ProductDetailsForm, ProductPublishForm } from ':frontend/components/product/productForms';
import { useProductForms, type ProductFormsState } from ':frontend/components/product/useProductForms';
import { trpc } from ':frontend/context/TrpcProvider';
import { type ProductOutput, type ProductType } from ':utils/entity/product';
import { routesFE } from ':utils/routes';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useParams, type NavigateFunction } from 'react-router-dom';
import { BackButton, NextStepButton, ProductContentTitle, ProductPhaseLabel, ProductPhonePreview } from './NewProduct';
import { useState } from 'react';
import { createErrorAlert, createTranslatedSuccessAlert } from ':frontend/components/notifications';
import useNotifications from ':frontend/context/NotificationProvider';
import { useTailwindMediaQuery } from ':frontend/hooks/useTailwindMediaQuery';

export function ProductDetail() {
    const { id } = useParams<{ id: ProductType }>();
    if (!id)
        throw new Error('Missing product id');

    const productQuery = trpc.product.getProduct.useQuery({ id });
    if (!productQuery.data)
        return null;

    return (
        <ProductDetailInner product={productQuery.data} />
    );
}

type ProductDetailInnerProps = Readonly<{
    product: ProductOutput;
}>;

function ProductDetailInner({ product }: ProductDetailInnerProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'newProduct' });

    // Disable the navigation only after the user made an edit.
    const [ state, dispatch, isFetching, blockerControl, navigateUnblocked ] = useProductForms(product, false);

    const showDesktopView = useTailwindMediaQuery({ minWidth: 'md' });
    const [ mobileViewShowPreview, setMobileViewShowPreview ] = useState(false);

    return (<>
        {showDesktopView ? (<>

            <Topbar>
                <div className='w-1/4 flex items-center gap-2'>
                    <DeleteButton state={state} navigateUnblocked={navigateUnblocked} />
                    {state.phase === 'publish' && (
                        <BackButton state={state} dispatch={dispatch} />
                    )}
                </div>

                {phasesOverview(state)}

                <div className='w-1/4 text-end'>
                    <NextStepButton state={state} dispatch={dispatch} isFetching={isFetching} />
                </div>
            </Topbar>

            <Content>
                <div className='w-full max-w-[920px] mx-auto px-4 py-12 flex justify-between'>
                    <div className='flex flex-col gap-6 w-[480px]'>
                        <ProductContentTitle state={state} />

                        {state.phase === 'details' ? (
                            <ProductDetailsForm state={state} dispatch={dispatch} />
                        ) : (
                            <ProductPublishForm state={state} dispatch={dispatch} />
                        )}
                    </div>

                    <ProductPhonePreview state={state} className='!sticky top-4' />
                </div>
            </Content>

        </>) : (<>

            <div className={clsx('flex flex-col h-full w-full', mobileViewShowPreview && 'blur-[10px]')}>
                <TopHeader>
                    <div className='flex items-center gap-4'>
                        <Button variant='transparent' size='small' onClick={() => setMobileViewShowPreview(true)} className='px-0'>
                            <Eye2Icon /> {t(`preview`)}
                        </Button>

                        <NextStepButton state={state} dispatch={dispatch} isFetching={isFetching} />
                    </div>
                </TopHeader>

                <div className='px-4 py-2 flex items-center gap-4'>
                    {state.phase === 'publish' && (
                        <BackButton state={state} dispatch={dispatch} />
                    )}

                    {phasesOverview(state)}

                    <DeleteButton state={state} navigateUnblocked={navigateUnblocked} />
                </div>

                <Content>
                    <div className='w-full max-w-[480px] mx-auto p-4 pb-8 space-y-6'>
                        <ProductContentTitle state={state} />

                        {state.phase === 'details' ? (
                            <ProductDetailsForm state={state} dispatch={dispatch} />
                        ) : (
                            <ProductPublishForm state={state} dispatch={dispatch} />
                        )}
                    </div>
                </Content>
            </div>

            {mobileViewShowPreview && (
                <div className='fixed top-0 bottom-0 left-0 right-0 z-50 bg-white/30 flex flex-col h-full'>
                    <button onClick={() => setMobileViewShowPreview(false)} className='p-4 text-primary block'>
                        <ArrowLeftIcon size='lg' />
                    </button>

                    <div className='px-4 pb-8 overflow-auto'>
                        <ProductPhonePreview state={state} className='h-full' />
                    </div>
                </div>
            )}

        </>)}

        <BlockNavigationModal control={blockerControl} />
    </>);
}

type DeleteButtonProps = Readonly<{
    state: ProductFormsState;
    navigateUnblocked: NavigateFunction;
}>;

function DeleteButton({ state, navigateUnblocked }: DeleteButtonProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'productDetail' });
    const [ isDeleteModalOpen, setIsDeleteModalOpen ] = useState(false);

    return (<>
        <Button variant='danger' size='small' className='max-md:w-9 max-md:px-0' onClick={() => setIsDeleteModalOpen(true)} aria-label={t('delete-button')}>
            <TrashXmarkIcon className='md:hidden' />
            <span className='max-md:hidden'>{t('delete-button')}</span>
        </Button>

        <DeleteProductModal
            product={isDeleteModalOpen && state.original || undefined}
            onCancel={() => setIsDeleteModalOpen(false)}
            onDeleted={() => navigateUnblocked(routesFE.products.list)}
        />
    </>);
}

function phasesOverview(state: ProductFormsState) {
    // TODO The phase labels are wrong (2 and 3 instead of 1 and 2).
    // They probably need a complete redesign tbh ...

    return (
        <div className='grow flex items-center justify-center gap-2'>
            <ProductPhaseLabel phase='details' currentPhase={state.phase} />
            <div className={clsx('w-full max-w-32', state.phase === 'publish' ? 'fl-bg-dashed-primary-x' : 'fl-bg-dashed-x')} />
            <ProductPhaseLabel phase='publish' currentPhase={state.phase} />
        </div>
    );
}

type DeleteProductModalProps = Readonly<{
    product?: ProductOutput;
    onCancel: () => void;
    onDeleted: () => void;
}>;

export function DeleteProductModal({ product, onCancel, onDeleted }: DeleteProductModalProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'productDetail.deleteModal' });
    const { addAlert } = useNotifications();

    const deleteMutation = trpc.product.deleteProduct.useMutation();
    const trpcUtils = trpc.useUtils();

    function deleteProduct() {
        if (!product)
            return;

        deleteMutation.mutate({ id: product.id }, {
            onError: error => {
                addAlert(createErrorAlert(error.data));
            },
            onSuccess: async () => {
                addAlert(createTranslatedSuccessAlert('pages:productDetail:delete-success'));
                await trpcUtils.product.getProducts.invalidate();
                onDeleted();
            },
        });
    }

    return (
        <Modal.Root open={!!product} onOpenChange={open => !open && onCancel()}>
            <Modal.Content className='max-w-md p-8 gap-6' closeButton={t('cancel-button')}>
                <Modal.Header className='items-center'>
                    <TrashXmarkIcon size={32} className='text-danger' />
                    <Modal.Title className='mt-5 text-center text-2xl leading-7'>{t('title')}</Modal.Title>
                    <Modal.Description className='mt-3 text-center text-lg leading-7'>{t('text')}</Modal.Description>
                </Modal.Header>
                <Modal.Footer className='grid grid-cols-2'>
                    <Button onClick={onCancel} variant='secondary'>
                        {t('cancel-button')}
                    </Button>
                    <SpinnerButton onClick={deleteProduct} variant='primary' isFetching={deleteMutation.isPending}>
                        {t('confirm-button')}
                    </SpinnerButton>
                </Modal.Footer>
            </Modal.Content>
        </Modal.Root>
    );
}
