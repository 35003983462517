import { ArrowLeftIcon, ArrowRightIcon, Check1Icon, Eye2Icon, SpaceshipIcon } from ':components/icons/basic';
import { Button, ScrollArea } from ':components/shadcn';
import { ProductPhonePreviewDisplay, productStyles } from ':components/store/product/ProductCard';
import { StoreBioDisplay } from ':components/store/StoreBioDisplay';
import { getStoreStyles } from ':components/store/utils';
import { BlockNavigationModal } from ':frontend/components/BlockNavigationModal';
import { SpinnerButton } from ':frontend/components/common';
import { Content, Topbar, TopHeader } from ':frontend/components/Layout';
import { ProductDetailsForm, ProductPublishForm } from ':frontend/components/product/productForms';
import { createProductPreview, useProductForms, type ProductFormsDispatch, type ProductFormsState } from ':frontend/components/product/useProductForms';
import { trpc } from ':frontend/context/TrpcProvider';
import { type ProductType } from ':utils/entity/product';
import { routesFE } from ':utils/routes';
import clsx from 'clsx';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useTailwindMediaQuery } from ':frontend/hooks/useTailwindMediaQuery';

export function NewProduct() {
    const { type } = useParams<{ type: ProductType }>();
    if (!type)
        throw new Error('Missing product type');

    const { t } = useTranslation('pages', { keyPrefix: 'newProduct' });
    // Disable the navigation in all cases because the user already did something (they selected the product type).
    const [ state, dispatch, isFetching, blockerControl ] = useProductForms({ type }, true);

    const showDesktopView = useTailwindMediaQuery({ minWidth: 'md' });
    const [ mobileViewShowPreview, setMobileViewShowPreview ] = useState(false);

    return (<>
        {showDesktopView ? (<>

            <Topbar>
                <div className='w-1/4'>
                    <BackButton state={state} dispatch={dispatch} />
                </div>

                {phasesOverview(state)}

                <div className='w-1/4 text-end'>
                    <NextStepButton state={state} dispatch={dispatch} isFetching={isFetching} />
                </div>
            </Topbar>

            <Content>
                <div className='w-full max-w-[920px] mx-auto px-4 py-12 flex justify-between'>
                    <div className='space-y-6 w-full max-w-[480px]'>
                        <ProductContentTitle state={state} />

                        {state.phase === 'details' ? (
                            <ProductDetailsForm state={state} dispatch={dispatch} />
                        ) : (
                            <ProductPublishForm state={state} dispatch={dispatch} />
                        )}
                    </div>

                    <ProductPhonePreview state={state} className='!sticky top-4' />
                </div>
            </Content>

        </>) : (<>

            <div className={clsx('flex flex-col h-full w-full', mobileViewShowPreview && 'blur-[10px]')}>
                <TopHeader>
                    <div className='flex items-center gap-4'>
                        <Button variant='transparent' size='small' onClick={() => setMobileViewShowPreview(true)} className='px-0'>
                            <Eye2Icon /> {t(`preview`)}
                        </Button>

                        <NextStepButton state={state} dispatch={dispatch} isFetching={isFetching} />
                    </div>
                </TopHeader>

                <div className='px-4 py-2 flex items-center gap-4'>
                    <BackButton state={state} dispatch={dispatch} />

                    {phasesOverview(state)}
                </div>

                <Content>
                    <div className='w-full max-w-[480px] mx-auto p-4 pb-8 space-y-6'>
                        <ProductContentTitle state={state} />

                        {state.phase === 'details' ? (
                            <ProductDetailsForm state={state} dispatch={dispatch} />
                        ) : (
                            <ProductPublishForm state={state} dispatch={dispatch} />
                        )}
                    </div>
                </Content>
            </div>

            {mobileViewShowPreview && (
                <div className='fixed top-0 bottom-0 left-0 right-0 z-50 bg-white/30 flex flex-col h-full'>
                    <button onClick={() => setMobileViewShowPreview(false)} className='p-4 text-primary block'>
                        <ArrowLeftIcon size='lg' />
                    </button>

                    <div className='px-4 pb-8 overflow-auto'>
                        <ProductPhonePreview state={state} className='h-full' />
                    </div>
                </div>
            )}

        </>)}

        <BlockNavigationModal control={blockerControl} />
    </>);
}

type BackButtonProps = Readonly<{
    state: ProductFormsState;
    dispatch: ProductFormsDispatch;
}>;

export function BackButton({ state, dispatch }: BackButtonProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'newProduct' });

    const navigate = useNavigate();

    function handleBack() {
        if (state.phase === 'details') {
            // In theory, the user should be able to navigate back if he didn't fill in any details, but it's not needed now.
            navigate(routesFE.products.list);
            return;
        }

        dispatch({ type: 'phase', value: 'back' });
    }

    return (
        <Button variant='transparent' size='small' onClick={handleBack} className='px-0'>
            <ArrowLeftIcon size='sm' />
            <span className='max-md:hidden'>{t(`${state.phase}.back-button`)}</span>
        </Button>
    );
}

type NextStepButtonProps = Readonly<{
    state: ProductFormsState;
    dispatch: ProductFormsDispatch;
    isFetching: boolean;
}>;

export function NextStepButton({ state, dispatch, isFetching }: NextStepButtonProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'newProduct' });

    return (<>
        {state.phase === 'details' && (
            // We want two separate components here because of how the spinner button measures its width.
            <SpinnerButton variant='dark' size='small' isFetching={isFetching} onClick={() => dispatch({ type: 'phase', value: 'continue' })}>
                {t('details.continue-button')}<ArrowRightIcon />
            </SpinnerButton>
        )}

        {state.phase === 'publish' && (
            <SpinnerButton variant='dark' size='small' isFetching={isFetching} onClick={() => dispatch({ type: 'phase', value: 'continue' })}>
                <SpaceshipIcon />{t('publish.continue-button')}
            </SpinnerButton>
        )}
    </>);
}

function phasesOverview(state: ProductFormsState) {
    return (
        <div className='w-full flex items-center justify-center gap-2'>
            <ProductPhaseLabel phase='type' currentPhase={state.phase} />
            <div className='w-full max-w-32 fl-bg-dashed-primary-x' />
            <ProductPhaseLabel phase='details' currentPhase={state.phase} />
            <div className={clsx('w-full max-w-32', state.phase === 'publish' ? 'fl-bg-dashed-primary-x' : 'fl-bg-dashed-x')} />
            <ProductPhaseLabel phase='publish' currentPhase={state.phase} />
        </div>
    );
}

const newProductPhases = [ 'type', 'details', 'publish' ];
type NewProductPhase = typeof newProductPhases[number];

type ProductPhaseLabelProps = Readonly<{
    phase: NewProductPhase;
    currentPhase: NewProductPhase;
}>;

export function ProductPhaseLabel({ phase, currentPhase }: ProductPhaseLabelProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'newProduct' });

    const index = newProductPhases.indexOf(phase);
    const isChecked = index < newProductPhases.indexOf(currentPhase);
    const isCurrent = phase === currentPhase;

    return (<>
        <div className={clsx('size-7 rounded-full border flex items-center justify-center shrink-0',
            isChecked || isCurrent ? 'text-primary border-primary bg-primary-50' : 'border-secondary-100 bg-white',
        )}>
            {isChecked ? (
                <Check1Icon size='xs' />
            ) : (
                <span>{index + 1}</span>
            )}
        </div>

        <span className={clsx('whitespace-nowrap', isCurrent ? 'text-primary' : 'max-lg:hidden')}>
            {t(`${phase}.phase-title`)}
        </span>
    </>);
}

export function ProductContentTitle({ state }: Readonly<{ state: ProductFormsState }>) {
    const { t } = useTranslation('pages', { keyPrefix: 'newProduct' });
    const styles = productStyles[state.type];

    return (
        <div className='flex items-center gap-4'>
            <div className={clsx('shrink-0 size-10 rounded-full flex items-center justify-center', styles.bg)}>
                {styles.icon({ size: 22 })}
            </div>

            <div>
                <h1 className='text-md leading-4 font-semibold text-secondary-900'>
                    {t(`${state.phase}.page-title`, { type: state.type })}
                </h1>
                <p className='leading-5 mt-1'>{t(`${state.phase}.page-description`)}</p>
            </div>
        </div>
    );
}

type ProductPhonePreviewProps = Readonly<{
    state: ProductFormsState;
    className?: string;
}>;

export function ProductPhonePreview({ state, className }: ProductPhonePreviewProps) {
    const store = trpc.store.getStore.useQuery().data;
    const locations = trpc.location.getLocations.useQuery().data;

    const product = useMemo(() => createProductPreview(state.type, state.details.form, locations ?? []), [ state.type, state.details.form, locations ]);

    if (!store)
        return null;

    const storeStyles = getStoreStyles(store.design, 'fl-store-bg');

    return (
        // Based on iPhone 11 + one pixel on each side for the border.
        <ScrollArea
            className={clsx(`
                w-full max-w-[377px] mx-auto max-h-[814px] rounded-2xl border border-[#e6e6e6]
                shadow-[1.3px_1.3px_4px_0px_rgba(80,88,113,0.25),5.3px_5.3px_13.2px_0px_rgba(80,88,113,0.08),4px_4px_7.9px_0px_rgba(121,135,176,0.08)_inset,0px_4px_4px_0px_rgba(0,0,0,0.25)]
                `, className)}
        >
            <div className={clsx('w-full max-w-[375px] min-h-[812px] px-5 py-6 flex flex-col items-center gap-6', storeStyles.className)} style={storeStyles.style}>
                <StoreBioDisplay bio={store.bio} image={store.image} />
                <ProductPhonePreviewDisplay product={product} />
            </div>
        </ScrollArea>
    );
}
