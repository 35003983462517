import { useCallback, useState } from 'react';
import { Form } from ':components/shadcn';
import { Trans, useTranslation } from 'react-i18next';
import { useUser } from ':frontend/context/UserProvider';
import { DateTime } from 'luxon';
import { ErrorType, SubscriptionFE, type SubscriptionError } from ':frontend/types/Subscription';
import { SpinnerButton } from ':frontend/components/common';
import { TeamMemberRole } from ':utils/entity/team';
import { SubscriptionTierCode, SubscriptionTierPaymentPeriod } from ':utils/entity/subscription';
import { trpc } from ':frontend/context/TrpcProvider';
import { UnselectablePlanError } from ':utils/error/generic.error';
import { UnselectablePlanErrorModal } from ':frontend/components/orders/SubscriptionErrorModal';
import { BenefitsList, SpecialOfferPurple } from ':frontend/components/upsell/UpsellModal';
import { cn } from ':components/shadcn/utils';
import useNotifications from ':frontend/context/NotificationProvider';
import { createErrorAlert, createTranslatedSuccessAlert } from ':frontend/components/notifications';

const TEAM_OPTION_BUTTON_LINK = 'https://app.lemcal.com/@stepancalta';

export function Subscriptions() {
    const { t } = useTranslation('pages', { keyPrefix: 'subscription' });
    const { subscription, role } = useUser();
    const [ subscriptionError, setSubscriptionError ] = useState<SubscriptionError>();

    if (role === TeamMemberRole.master) {
        return (
            <div className='p-4 py-8 md:py-12 text-center w-full text-xl whitespace-pre-line leading-normal'>
                <Trans
                    t={t}
                    i18nKey='master-text'
                    components={{
                        email: <a href='mailto:info@flowlance.com' target='_blank' rel='noreferrer' className='underline' />,
                        meeting: <a href={TEAM_OPTION_BUTTON_LINK} target='_blank' rel='noreferrer' className='underline'  />,
                    }}
                />
            </div>
        );
    }

    return (
        <div className='p-4 py-8 md:py-12'>
            <UnselectablePlanErrorModal error={subscriptionError} onHide={() => setSubscriptionError(undefined)} />

            <div className='grid grid-cols-1 sm:grid-cols-2 gap-4 max-w-[1044px] w-full mx-auto'>
                <CurrentPlan onError={setSubscriptionError}/>

                {subscription.isPro ? (
                    <BusinessPlanSelected />
                ) : (
                    <BusinessPlanAdvertisement onError={setSubscriptionError} />
                )}
            </div>
        </div>
    );
}

function CurrentPlan({ onError }: { onError?: (error: SubscriptionError) => void }) {
    const { t } = useTranslation('pages', { keyPrefix: 'subscription' });
    const { subscription } = useUser();

    const { buySubscription, isFetching } = useSubscriptionUpsert();

    function continueFree() {
        buySubscription(SubscriptionTierCode.free, SubscriptionTierPaymentPeriod.monthly, onError);
    }

    return (
        <div className={cn(
            'p-4 sm:p-9 flex flex-col gap-9 justify-between w-full border border-primary rounded-2xl bg-white shadow-[0px_2px_10px_0px_rgba(0,0,0,0.10)]',
            !subscription.isPro && 'sm:self-start',
        )}>
            <div>
                <h1 className='text-xl font-semibold leading-none mb-4'>{t(`${subscription.code}.label`)}</h1>
                <h2 className='text-4xl font-semibold leading-none mb-2'>{t(`${subscription.code}.price-${subscription.paymentPeriod}`)}</h2>
                <p className='text-[15px] text-primary font-semibold leading-none'>{t('current-plan')}</p>
                {!subscription.isActive && (
                    <p className='text-danger-600 text-lg/6 mt-3'>{t('plan-inactive')}</p>
                )}
            </div>
            <div className='flex gap-3 items-center'>
                <ManagePlan />
                {!subscription.isActive && (
                    <SpinnerButton
                        variant='outline'
                        size='small'
                        isFetching={isFetching}
                        onClick={continueFree}
                    >
                        {t('continue-free')}
                    </SpinnerButton>
                )}
            </div>
        </div>
    );
}

function BusinessPlanSelected() {
    const { t } = useTranslation('pages', { keyPrefix: 'subscription' });

    return (
        <div className='p-4 sm:p-9 flex flex-col gap-4 w-full bg-transparent rounded-2xl shadow-none border border-black/10'>
            <img src='/static/images/superhero256.png' className='size-32 rounded-lg select-none drag-none' draggable={false} />
            <h1 className='text-4xl font-semibold leading-10'>{t('superhero')}</h1>
            <p className='leading-normal'>{t('grateful')}</p>
        </div>
    );
}

function BusinessPlanAdvertisement({ onError }: { onError: (error: SubscriptionError) => void }) {
    const { t } = useTranslation('pages', { keyPrefix: 'subscription' });
    const { team } = useUser();

    const { buySubscription, isFetching } = useSubscriptionUpsert();

    const [ period, setPeriod ] = useState<SubscriptionTierPaymentPeriod>(SubscriptionTierPaymentPeriod.yearly);

    return (
        <div className='p-4 sm:p-9 flex flex-col gap-6 bg-transparent rounded-2xl shadow-none border border-black/10'>
            <div className='flex flex-wrap gap-y-4 justify-between items-end'>
                <div>
                    <h1 className='text-xl font-semibold leading-none mb-4'>{t(`paid.label`)}</h1>
                    <h2 className='text-4xl font-semibold leading-none tabular-nums'>{t(`paid.price-${period}`)}</h2>
                </div>

                <div className='shrink-0 flex gap-2 text-secondary-400'>
                    <Form.SwitchBetween
                        isRight={period === SubscriptionTierPaymentPeriod.yearly}
                        onIsRightChange={value => setPeriod(value ? SubscriptionTierPaymentPeriod.yearly : SubscriptionTierPaymentPeriod.monthly)}
                        labelLeft={t('monthly')}
                        labelRight={t('yearly')}
                    />
                </div>
            </div>

            {/* <SpecialOfferBasic className='bg-secondary-100' /> */}
            <SpecialOfferPurple period={period} />

            <BenefitsList />

            <div>
                <SpinnerButton
                    isFetching={isFetching}
                    onClick={() => {
                        buySubscription(SubscriptionTierCode.paid, period, onError);
                    }}
                    className='rounded-full w-full py-2'
                >
                    {!team.dateTrialStarted ? t('start-trial') : t('buy-now')}
                </SpinnerButton>

                <div className='text-center text-sm text-secondary-400 mt-3 mb-6 mx-auto'>{t('cancel-note')}</div>

                <div className='text-center mx-auto max-w-[250px] leading-5'>{t('footer-note')}</div>
            </div>
        </div>
    );
}

function ManagePlan() {
    const { t } = useTranslation('pages', { keyPrefix: 'subscription' });

    const { goToSubscriptionSession, isFetching } = useSubscriptionUpsert();

    return (
        <SpinnerButton
            variant='outline'
            size='small'
            isFetching={isFetching}
            onClick={goToSubscriptionSession}
        >
            {t('manage-plan-button')}
        </SpinnerButton>
    );
}

export function getClientReferenceId(): string {
    const rewardfulReferenceId =
        'Rewardful' in window &&
        typeof window.Rewardful === 'function' &&
        'referral' in window.Rewardful &&
        typeof window.Rewardful.referral === 'string' &&
        window.Rewardful.referral;

    return rewardfulReferenceId || ('checkout_'+ DateTime.now().toMillis());
}

export function useSubscriptionUpsert() {
    const { subscription, setSubscription } = useUser();
    const { addAlert } = useNotifications();

    const createSubscriptionSessionMutation = trpc.$subscription.createSubscriptionSession.useMutation();
    const upsertSubscriptionMutation = trpc.$subscription.upsertSubscription.useMutation();

    const goToSubscriptionSession = useCallback(() => {
        createSubscriptionSessionMutation.mutate(undefined, {
            onError: error => {
                addAlert(createErrorAlert(error.data));
            },
            onSuccess: response => {
                window.location.href = response.continueUrl;
            },
        });
    }, [ createSubscriptionSessionMutation.mutate ]);

    const buySubscription = useCallback((code: SubscriptionTierCode, rawPaymentPeriod: SubscriptionTierPaymentPeriod, onError?: (error: SubscriptionError) => void) => {
        // The free subscription exists only in the monthy variant.
        const paymentPeriod = code === SubscriptionTierCode.free ? SubscriptionTierPaymentPeriod.monthly : rawPaymentPeriod;

        // If the desired subscription is same as the current, we redirect appUser to the customer portal without code and paymentPeriod.
        if (
            subscription.isActive
            && subscription.code === code
            && subscription.paymentPeriod === paymentPeriod
        ) {
            goToSubscriptionSession();
            return;
        }

        // If none of the above is true, we redirect him to a page where he can buy a new subscription.
        upsertSubscriptionMutation.mutate({ code, paymentPeriod, clientReferenceId: getClientReferenceId() }, {
            onError: error => {
                if (error.message === UnselectablePlanError.type)
                    onError?.({ type: ErrorType.PlanUnselectable });
                else
                    addAlert(createErrorAlert(error.data));
            },
            onSuccess: response => {
                if ('subscription' in response) {
                    const newSubscription = SubscriptionFE.fromServer(response.subscription);
                    setSubscription(newSubscription);
                    addAlert(createTranslatedSuccessAlert('pages:subscription.plan-changed'));
                }
                else {
                    window.location.href = response.continueUrl;
                }
            },
        });
    }, [ subscription, setSubscription, goToSubscriptionSession, upsertSubscriptionMutation.mutate ]);

    return {
        goToSubscriptionSession,
        buySubscription,
        isFetching: createSubscriptionSessionMutation.isPending || upsertSubscriptionMutation.isPending,
    };
}
