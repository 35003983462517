import { useCallback, useMemo, useState } from 'react';
import { Form, Modal } from ':components/shadcn';
import { useTranslation } from 'react-i18next';
import { useClients, useSortOrder } from ':frontend/hooks';
import { type InvoicingProfileFE } from ':frontend/types/Invoicing';
import { type ClientInfoFE } from ':frontend/types/Client';
import useNotifications from ':frontend/context/NotificationProvider';
import { SpinnerButton } from '../common';
import { trpc } from ':frontend/context/TrpcProvider';
import { FileUserIcon } from ':components/icons/basic';
import { createErrorAlert, createTranslatedSuccessAlert } from '../notifications';
import { ClientSelect } from '../client/ClientSelect';

type AssignClientModalProps = Readonly<{
    profile: InvoicingProfileFE;
    onClose(): void;
}>;

export function AssignClientModal({ profile, onClose }: AssignClientModalProps) {
    // TODO cache the profile

    const { t } = useTranslation('components', { keyPrefix: 'assignClientModal' });
    const [ nameOrder ] = useSortOrder();
    const { addClients } = useClients({ invoicingProfileId: profile.id, nameOrder });
    const { clients: allClients, setClients: setAllClients } = useClients({ default: [] });
    const { addAlert } = useNotifications();
    const [ selectedClient, setSelectedClient ] = useState<null|ClientInfoFE>(null);

    const clientUpdateMutation = trpc.$client.updateClient.useMutation();
    const trpcUtils = trpc.useUtils();

    const addClient = useCallback(() => {
        if (!selectedClient)
            return;

        clientUpdateMutation.mutate({ id: selectedClient.id, invoicingProfileId: profile.id }, {
            onError: error => {
                addAlert(createErrorAlert(error.data));
            },
            onSuccess: async () => {
                await trpcUtils.$client.getClientInfos.invalidate();
                addAlert(createTranslatedSuccessAlert('pages:settings.invoicing-profile.add-client-success'));
                onClose();
            },
        });
    }, [ profile.id, selectedClient, addClients, setAllClients, addAlert, clientUpdateMutation.mutate ]);

    const availableClients = useMemo(() => allClients.filter(c => c.invoicingProfileId !== profile.id), [ profile.id, allClients ]);

    return (
        <Modal.Root
            open
            onOpenChange={() => onClose()}
        >
            <Modal.Content closeButton={null} className='gap-6'>
                <Modal.Header className='space-y-4'>
                    <FileUserIcon size={32} className='mx-auto text-primary stroke-2' />

                    <Modal.Title className='text-3xl leading-9 text-center font-semibold text-secondary-900'>
                        {t('title')}
                    </Modal.Title>
                </Modal.Header>

                {availableClients.length > 0 && <div>
                    <Form.Label>{t('assign-client-label')}</Form.Label>

                    <ClientSelect
                        clients={availableClients}
                        value={selectedClient || undefined}
                        onChange={newClient => {
                            if (newClient)
                                setSelectedClient(newClient);
                            else
                                setSelectedClient(null);
                        }}
                        showEmails
                        disabled={clientUpdateMutation.isPending}
                    />
                </div>}

                {availableClients.length === 0 && <div className='font-semibold text-danger-500 text-center'>{t('no-client-to-select')}</div>}

                <Modal.Footer>
                    <SpinnerButton
                        variant='primary'
                        isFetching={clientUpdateMutation.isPending}
                        onClick={addClient}
                        disabled={!selectedClient}
                        className='w-full'
                    >
                        {t('confirm-button')}
                    </SpinnerButton>
                </Modal.Footer>
            </Modal.Content>
        </Modal.Root>
    );
}
