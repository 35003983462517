import { z, type ZodSchema } from 'zod';
import { zId } from ':utils/id';
import { zCustomItemInit, zDiscountItemInit, zEventItemInit, zOrderItemEdit, zOrderItemOutput } from './orderItem';
import { zClientInfoOutput, zClientOrContactInit } from './client';
import { zCurrencyId } from './money';
import { DocumentType, OrderState, OrderTransition, PaymentMethod, zInvoicingIdentityOutput, zInvoicingIdentityUpdate } from './invoicing';
import { zDateTime, zDateRange, type DateRange } from ':utils/dateTime';
import { zOrderBy, zPage } from ':utils/query';
import { DateTime, type Zone } from 'luxon';
import { ProductType } from './product';
import { zVisitOutput } from './store';

// Logs

export enum OrderLogType {
    Created = 'order.created',
    NotificationSent = 'order.notificationSent',
    Updated = 'order.updated',
    Transition = 'order.transition',
}

export enum OrderCreatedFrom {
    App = 'app',
    Store = 'store',
}

export function zLogOutputFunction<TData extends ZodSchema>(data: TData) {
    return z.object({
        id: zId,
        createdAt: zDateTime,
        data,
    });
}

export const zOrderLogData = z.discriminatedUnion('type', [
    z.object({
        type: z.literal(OrderLogType.Created),
    }),
    z.object({
        type: z.literal(OrderLogType.NotificationSent),
        email: z.string(),
    }),
    z.object({
        type: z.literal(OrderLogType.Updated),
    }),
    z.object({
        type: z.literal(OrderLogType.Transition),
        before: z.nativeEnum(OrderState),
        after: z.nativeEnum(OrderState),
        transition: z.nativeEnum(OrderTransition),
        isAutomatic: z.boolean(),
    }),
]);

export const zOrderLogOutput = zLogOutputFunction(zOrderLogData);

// Outputs

const zCommonOrderOutput = z.object({
    id: zId,
    documentType: z.nativeEnum(DocumentType),
    // could be changed to something like OrderType if we want to color event/custom orders differently
    productType: z.nativeEnum(ProductType).optional(),
    client: zClientInfoOutput,
    prefix: z.string().optional(),
    index: z.number().optional(),
    title: z.string(),
    total: z.number(),
    currencyId: zCurrencyId,
    state: z.nativeEnum(OrderState),
    createdAt: zDateTime,
    issueDate: zDateTime,
    paymentMethod: z.nativeEnum(PaymentMethod),
});

export type OrderInfoOutput = z.infer<typeof zOrderInfoOutput>;
export const zOrderInfoOutput = zCommonOrderOutput.extend({
    /** AppUser */
    schedulerIds: z.array(zId),
});

export type SchedulerOrderInfoOutput = z.infer<typeof zSchedulerOrderInfoOutput>;
export const zSchedulerOrderInfoOutput = z.object({
    id: zId,
    client: zClientInfoOutput,
    title: z.string(),
    state: z.nativeEnum(OrderState),
    createdAt: z.string(),  // TODO zDateTime ?
});

export type OrderCustomFields = z.infer<typeof zOrderCustomFields>;
export const zOrderCustomFields = z.object({
    header: z.string().optional(),
    footer: z.string().optional(),
    customKey1: z.string().optional(),
    customValue1: z.string().optional(),
    customKey2: z.string().optional(),
    customValue2: z.string().optional(),
});

export type OrderOutput = z.infer<typeof zOrderOutput>;
export const zOrderOutput = zCommonOrderOutput.merge(zOrderCustomFields).extend({
    supplier: zInvoicingIdentityOutput,
    subscriber: zInvoicingIdentityOutput,
    variableSymbol: z.string().optional(),
    items: z.array(zOrderItemOutput),
    logs: z.array(zOrderLogOutput),
    isNotificationSent: z.boolean(),
    dueDate: zDateTime,
    taxDate: zDateTime,
    condensedInvoice: z.boolean(),
});

export function generateVariableSymbol(issueDate: DateTime, index: number): string {
    return issueDate.toFormat('yymm') + index.toString().padStart(4, '0');
}

export type NotificationInit = z.infer<typeof zNotificationInit>;
export const zNotificationInit = z.object({
    /** If undefined, the client's email will be used instead. */
    email: z.string().optional(),
    cc: z.array(z.string()).optional(),
    subject: z.string(),
    body: z.string(),
});

export const emailVariables = {
    invoiceLink: '#invoiceLink#',
    receiptLink: '#receiptLink#',
    paymentLink: '#paymentLink#',
} as const;
export type EmailVariable = typeof emailVariables[keyof typeof emailVariables];

const zOrderInitBase = z.object({
    paymentMethod: z.nativeEnum(PaymentMethod),
    dueDays: z.number().optional(),
    notification: zNotificationInit.optional(),
    createdFrom: z.nativeEnum(OrderCreatedFrom),
});

export type EventOrderInit = z.infer<typeof zEventOrderInit>;
export const zEventOrderInit = zOrderInitBase.and(z.object({
    /** There should be one item for each client. */
    eventItems: z.array(zEventItemInit),
}));

export type ProductOrderInit = z.infer<typeof zProductOrderInit>;
export const zProductOrderInit = zOrderInitBase.and(z.object({
    // TODO remove title and calculate it on BE
    title: z.string(),
    client: zClientOrContactInit,
    guest: zClientOrContactInit,
    scheduler: zId.optional(),

    /** Product */
    productItems: z.array(zId),
    discountItems: z.array(zDiscountItemInit),
}));

export type CustomOrderInit = z.infer<typeof zCustomOrderInit>;
export const zCustomOrderInit = zOrderInitBase.and(z.object({
    // TODO remove title and calculate it on BE
    title: z.string(),
    client: zClientOrContactInit,

    currencyId: zCurrencyId,
    items: z.array(zCustomItemInit),
}));

export type OrderInit = z.infer<typeof zOrderInit>;
export const zOrderInit = z.union([
    z.object({
        event: zEventOrderInit,
    }),
    z.object({
        product: zProductOrderInit,
    }),
    z.object({
        custom: zCustomOrderInit,
    }),
]);

/** Like PATCH. */
export type OrderEdit = z.infer<typeof zOrderEdit>;
export const zOrderEdit = z.object({
    prefix: z.string(),
    index: z.number(),
    title: z.string(),
    variableSymbol: z.string(),
    issueDate: zDateTime,
    dueDate: zDateTime,
    taxDate: zDateTime,
    condensedInvoice: z.boolean(),
    /** Like PUT - if anything changes, the object is replaced. */
    subscriber: zInvoicingIdentityUpdate,
    /** Like PUT - if anything changes, the object is replaced. */
    supplier: zInvoicingIdentityUpdate,
    /** Like PUT - if anything changes, the object is replaced. */
    fields: zOrderCustomFields,
    /** Like PUT - if anything changes on a specific item, the item is replaced. */
    orderItems: z.array(zOrderItemEdit),
}).partial().and(z.object({
    id: zId,
}));

export enum OrderStatsRange {
    /** available in Free plan */
    today = 'today',
    /** available in Free plan */
    last7Days = 'last7Days',
    /** available only in Pro plan */
    last30Days = 'last30Days',
    /** available only in Pro plan */
    last12Months = 'last12Months',
    /** not supported now */
    custom = 'custom',
}

export type OrderStatsInput = z.infer<typeof zOrderStatsInput>;
export const zOrderStatsInput = z.object({ range: z.enum([
    OrderStatsRange.today,
    OrderStatsRange.last7Days,
    OrderStatsRange.last30Days,
    OrderStatsRange.last12Months,
]) }).or(z.object({
    range: z.literal(OrderStatsRange.custom),
    start: zDateTime,
    end: zDateTime,
}));

export function statsRangeToDateRange(input: OrderStatsInput, timezone: string | Zone): DateRange {
    const now = DateTime.now().setZone(timezone);

    switch (input.range) {
    case OrderStatsRange.today:
        return { start: now.startOf('day'), end: now };
    case OrderStatsRange.last7Days:
        return { start: now.minus({ days: 7 }).startOf('day'), end: now };
    case OrderStatsRange.last30Days:
        return { start: now.minus({ days: 31 }).startOf('day'), end: now };
    case OrderStatsRange.last12Months:
        return { start: now.minus({ months: 12 }).startOf('month'), end: now };
    case OrderStatsRange.custom:
        return { start: input.start, end: input.end };
    }
}

export type OrderStatsOrder = z.infer<typeof zOrderStatsOrder>;
const zOrderStatsOrder = z.object({
    issueDate: zDateTime,
    /** in cents */
    total: z.number(),
    state: z.nativeEnum(OrderState),
    createdFrom: z.nativeEnum(OrderCreatedFrom),
});

export type ProductStats = z.infer<typeof zProductStats>;
export const zProductStats = z.object({
    id: zId,
    type: z.nativeEnum(ProductType),
    title: z.string(),
    /** in cents */
    revenue: z.number(),
    buys: z.number(),
});

export type OrderStatsProduct = z.infer<typeof zOrderStatsProduct>;
const zOrderStatsProduct = zProductStats.extend({
    leads: z.number(),
});

export type OrderStatsOutput = z.infer<typeof zOrderStatsOutput>;
export const zOrderStatsOutput = z.object({
    visits: z.array(zVisitOutput),
    leads: z.array(zVisitOutput),
    orders: zOrderStatsOrder.array(),
    products: zOrderStatsProduct.array(),
    range: zDateRange,
    currency: zCurrencyId,
});

export function isStripeOrder({ paymentMethod }: { paymentMethod: PaymentMethod }): boolean {
    return paymentMethod === PaymentMethod.stripe;
}

export const zOrderIndex = z.number().int().min(1).max(9999);

export const zGetOrdersQuery = z.object({
    state: z.array(z.nativeEnum(OrderState)),
    client: z.array(zId),
    invoicingProfile: zId,
    createdAfter: zDateTime,
    createdBefore: zDateTime,

    page: zPage,
    orderBy: zOrderBy([ 'createdAt', 'index' ]),
}).partial();

export enum ExportOrdersFormat {
    PdfZip = 'pdf-zip',
    Pdf = 'pdf',
    Csv = 'csv',
    Isdoc = 'isdoc',
}

export type ExportOrdersQuery = z.infer<typeof zExportOrdersQuery>;
export const zExportOrdersQuery = z.object({
    format: z.nativeEnum(ExportOrdersFormat),
    state: z.array(z.nativeEnum(OrderState)).optional(),
    client: z.array(zId).optional(),
    createdAfter: zDateTime.optional(),
    createdStrictlyBefore: zDateTime.optional(),
});
