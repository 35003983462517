import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Form } from ':components/shadcn';
import { RHFErrorMessage } from ':frontend/components/forms/ErrorMessage';
import { SpinnerButton } from ':frontend/components/common';
import useNotifications from ':frontend/context/NotificationProvider';
import { Variant, Visibility } from ':frontend/types/notifications';
import { routesFE } from ':utils/routes';
import { GoogleOauthButton } from ':frontend/components/auth/googleButton';
import { useAuth } from ':frontend/context/AuthProvider';
import { createTranslatedErrorAlert } from ':frontend/components/notifications';
import { canonizeEmail } from ':utils/forms';
import { flowlanceIcons } from ':components/icons/logos';

type ResetPasswordFormData = {
    email: string;
};

export default function ResetPassword() {
    const { t } = useTranslation('pages', { keyPrefix: 'resetPassword' });
    const { t: tf } = useTranslation('common', { keyPrefix: 'form' });
    const [ isFetching, setIsFetching ] = useState(false);
    const [ isGoogleAccount, setIsGoogleAccount ] = useState(false);
    const { auth } = useAuth();

    const { register, handleSubmit, formState: { errors } } = useForm<ResetPasswordFormData>();
    const { addAlert } = useNotifications();
    const navigate = useNavigate();

    async function onSubmit(data: ResetPasswordFormData) {
        const canonizedEmail = canonizeEmail(data.email);
        setIsFetching(true);
        const response = await auth.resetPassword(canonizedEmail);
        setIsFetching(false);

        if (!response.status) {
            if (response.error === 'auth.resetPassword.google') {
                setIsGoogleAccount(true);
                return;
            }

            setIsGoogleAccount(false);
            addAlert(createTranslatedErrorAlert(), Visibility.Unauthorized);
            return;
        }

        addAlert({ message: t('success'), variant: Variant.Success }, Visibility.Global);
    }

    return (
        <div className='min-w-80 h-screen w-screen bg-secondary-50 flex md:items-center md:justify-center overflow-auto'>
            <div className='w-full md:w-[400px] space-y-4 p-10 md:p-0'>
                <div className='flex flex-col justify-center gap-8 h-full'>
                    <div className='space-y-4'>
                        <flowlanceIcons.FlowlanceBanner />
                        <h1 className='text-2xl font-semibold leading-8 md:text-4xl md:leading-10'>{t('page-title')}</h1>
                    </div>

                    <Form.Root onSubmit={handleSubmit(onSubmit)} className='w-full space-y-4'>
                        <div>
                            <Form.Input
                                label={t('email-label')}
                                type='email'
                                className='text-lg md:text-base'
                                {...register('email', { required: tf('email-required') })}
                                autoFocus
                            />
                            <RHFErrorMessage errors={errors} name='email' />
                        </div>

                        <SpinnerButton
                            type='submit'
                            className='w-full mt-4'
                            isFetching={isFetching}
                        >
                            {t('reset-password-button')}
                        </SpinnerButton>
                    </Form.Root>

                    {isGoogleAccount ? (
                        <div className='w-full p-4 rounded-lg bg-danger/10 space-y-4'>
                            <p className='text-center text-danger'>{t('via-google')}</p>
                            <GoogleOauthButton type='login' variant='outline' />
                        </div>
                    ) : (<>
                        <div className='text-lg'>{t('or-label')}</div>
                        <div className='space-y-2'>
                            <Button className='w-full bg-transparent' variant='outline' onClick={() => navigate(routesFE.login.index.path)}>
                                {t('login-link')}
                            </Button>

                            <Button className='w-full bg-transparent' variant='outline' onClick={() => navigate(routesFE.register.claimUrl)}>
                                {t('register-link')}
                            </Button>
                        </div>
                    </>)}
                </div>
            </div>
        </div>
    );
}
