import { useCallback } from 'react';
import { GeneralSettings } from './GeneralSettings';
import { AdvancedSettings } from './AdvancedSettings';
import { InvoicingProfileSettings } from './InvoicingProfileSettings';
import { PaymentsSettings } from './PaymentsSettings';
import { InvoicingProfilesSettings } from './InvoicingProfilesSettings';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { routesFE } from ':utils/routes';
import { toMaster, useUser } from ':frontend/context/UserProvider';
import { ModulesSettings } from './ModulesSettings';
import { Tabs } from ':components/shadcn';
import { WindowPlus2Icon } from ':components/icons/basic';
import { Subscriptions } from './Subscriptions';
import { TabsTopbar } from ':frontend/components/Layout';

export default function Settings() {
    const { t } = useTranslation('pages', { keyPrefix: 'settings' });
    const { key } = useParams();
    const navigate = useNavigate();

    const userContext = useUser();
    const masterContext = toMaster(userContext);

    const handleSelect = useCallback((newKey: string | null) => newKey && navigate(routesFE.settings.resolve({ key: newKey })), [ navigate ]);

    return (
        <Tabs.Root value={key} onValueChange={handleSelect} className='flex flex-col h-full'>
            <TabsTopbar>
                <Tabs.List>
                    <Tabs.Trigger value='general'>{t('general-tab')}</Tabs.Trigger>
                    {masterContext && (<>
                        {masterContext.teamSettings.isInvoicingEnabled &&
                                <Tabs.Trigger value='invoicing'>{t('invoicing-tab')}</Tabs.Trigger>
                        }
                        <Tabs.Trigger value='payments'>{t('payments-tab')}</Tabs.Trigger>
                        <Tabs.Trigger value='subscription'>{t('subscription-tab')}</Tabs.Trigger>
                    </>)}
                    <Tabs.Trigger value='advanced'>{t('advanced-tab')}</Tabs.Trigger>
                    {masterContext && (
                        <Tabs.Trigger value='modules' aria-label={t('modules-tab')}><WindowPlus2Icon size='md' className='-my-px' /></Tabs.Trigger>
                    )}
                </Tabs.List>
            </TabsTopbar>

            <div className='fl-main-scroller'>
                <div className='max-w-[800px] w-full mx-auto'>
                    <Tabs.Content value='general'>
                        <GeneralSettings />
                    </Tabs.Content>
                    {masterContext && (masterContext.profiles.length === 1 ? (
                        <Tabs.Content value='invoicing'>
                            <InvoicingProfileSettings />
                        </Tabs.Content>
                    ) : (
                        <Tabs.Content value='invoicing'>
                            <InvoicingProfilesSettings />
                        </Tabs.Content>
                    ))}
                    {masterContext && (
                        <Tabs.Content value='payments'>
                            <PaymentsSettings />
                        </Tabs.Content>
                    )}
                    <Tabs.Content value='advanced'>
                        <AdvancedSettings />
                    </Tabs.Content>
                    {masterContext && (
                        <Tabs.Content value='modules'>
                            <ModulesSettings />
                        </Tabs.Content>
                    )}
                </div>
                {/* Subscriptions page has a specific width */}
                {masterContext && (
                    <Tabs.Content value='subscription'>
                        <Subscriptions />
                    </Tabs.Content>
                )}
            </div>
        </Tabs.Root>
    );
}
